import React, { Component } from 'react'

import '../css/Footer.css';

class Footer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            content: [
                      {
                          "title": {
                              "en": "About Us",
                              "zh": "關於我們",
                              "ja": "会社情報"
                          },

                          "content": {
                              "en": ["Home", "About Nexuni", "Technology", "Life At Nexuni"],
                              "zh": ["首頁", "公司理念", "核心技術", "文化與環境"],
                              "ja": ["ホーム", "Nexuni について", "技術", "企業文化"]
                          },

                          "action": [() => this.props.general_switch_page('/MainPage', ''),
                                  () => this.props.general_switch_page('/GeneralPage', "about_us"),
                                  () => this.props.general_switch_page('/GeneralPage', "core_technology"),
                                  () => this.props.general_switch_page('/GeneralPage', 'environment')]
                      },
                      {
                          "title": {
                              "en": "Solutions",
                              "zh": "Nexuni 自動化",
                              "ja": "Nexuni 自動化"
                          },
                          "content": {
                              "en": ["Solutions", "Customer Stories", "How to Collaborate?"],
                              "zh": ["解決方案", "實際案例", "合作流程"],
                              "ja": ["ソリューション", "顧客事例", "協力方法"]
                          },

                          "action": [() => this.props.general_switch_page('/SolutionPage', ''),
                                  () => this.props.general_switch_page('/StoryPage', ""),
                                  () => this.props.general_switch_page('/GeneralPage', 'colab_flow')]
                      },
                      {
                          "title": {
                              "en": "Careers",
                              "zh": "Nexuni 生涯",
                              "ja": "採用情報"
                          },

                          "content": {
                              "en": ["Open Positions", "Internship Program"],
                              "zh": ["開放職缺", "實習生計畫"],
                              "ja": ["募集職種", "インターンシッププログラム"]
                          },

                          "action": [() => this.props.general_switch_page('/GeneralPage', 'career'), 
                                  () => this.props.general_switch_page('/GeneralPage', 'intern')] // () => this.props.general_switch_page('/GeneralPage', 'partner')
                      },
                      {
                          "title": {
                              "en": "Media",
                              "zh": "媒體影音",
                              "ja": "メディア"
                          },

                          "content": {
                              "en": ["Media", "Blog", "Youtube"],
                              "zh": ["媒體", "部落格", "Youtube"],
                              "ja": ["メディア", "ブログ", "Youtube"]
                          },

                          "action": [() => this.props.general_switch_page('/GeneralPage', 'news'), 
                                  () => this.props.general_switch_page('/BlogPage', ''),
                                  () => this.openInNewTab('https://www.youtube.com/@nexunico.5980')]
                      },
                      {
                          "title": {
                              "en": "Contact Us",
                              "zh": "聯繫我們",
                              "ja": "お問い合わせ"
                          },

                          "content": {
                              "en": ["Facebook", "Instagram", "Linkedin", "Contact us"],
                              "zh": ["Facebook", "Instagram", "Linkedin", "聯絡我們"],
                              "ja": ["Facebook", "Instagram", "Linkedin", "お問い合わせ"]
                          },
                          "action": [() => this.openInNewTab('https://www.facebook.com/profile.php?id=100070603490643'), 
                                  () => this.openInNewTab('https://www.instagram.com/nexuni.co/'), 
                                  () => this.openInNewTab('https://www.linkedin.com/company/nexuni'),
                                  () => this.props.general_switch_page('/ContactUsPage', '')]
                      }]
        }

      this.openInNewTab = this.openInNewTab.bind(this)
    }

    openInNewTab(url) {
        var win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    }
    
    
    render() {

        return (
            <div className="v2_footer_section">
                <div className="v2_footer_btn_section">

                    {this.state.content.map((item, index) => (
                        <div key={index} className="v2_footer_btn_list">
                            <h4>{item["title"][this.props.lang]}</h4>
                            {item["action"].map((action, i) => (
                                <button key={i} onClick={action}><span>{item["content"][this.props.lang][i]}</span></button>
                            ))}
                        </div>
                    ))}
                    
                </div>
                <div className="v2_copyright_section">
                    <div className="v2_copyright">&copy;2024 - <strong>Nexuni Co Ltd. All rights reserved.</strong></div>
                </div>
            </div>
        );
    }
}

export default Footer;