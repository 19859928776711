import logo from './logo.svg';
import './App.css';

import React from 'react'
import {
 BrowserRouter as Router,
 Routes,
 Route
} from "react-router-dom";

import MainPage from './containers/MainPage'
import SolutionPage from './containers/SolutionPage'
import TechPage from './containers/TechPage'
import GeneralPage from './containers/GeneralPage'
import StoryPage from './containers/StoryPage'
import BlogPage from './containers/BlogPage'
import ContactUsPage from './containers/ContactUsPage'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MainPage/>} />
          <Route path="/MainPage" element={<MainPage/>} />
          <Route path="/SolutionPage" element={<SolutionPage/>} />
          <Route path="/ContactUsPage" element={<ContactUsPage />}/>
          <Route path="/BlogPage" element={<BlogPage />}/>
          <Route path="/StoryPage" element={<StoryPage />}/>
          <Route path="/GeneralPage" element={<GeneralPage />}/>
          <Route path="/TechPage" element={<TechPage />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;