import React, { Component } from 'react'

class IconWord extends Component {

    emptyFunc() {}
    
    render() {
        var t_style = this.props.style["text"];
        var i_style = this.props.style["img"];

        return (
            <div className="icon_word_section" onClick={this.props.action !== "" ? this.props.action : this.emptyFunc}>
                <div className="img_holder">
                    <img src={`${process.env.PUBLIC_URL}/image/` + this.props.img} style={i_style}/>
                </div>
                <div className="info_holder">
                    <h1 style={t_style}>{this.props.title}</h1>
                </div>
            </div>
        );
    }
}

export default IconWord;