import React, { Component } from 'react'

class SmallWordPicSec extends Component {

    emptyFunc() {}
    
    
    render() {
        var h_style = this.props.style["holder"];
    	var t_style = this.props.style["text"];
    	var i_style = this.props.style["img"];
        return (
        	<div className={this.props.action && this.props.action !== "" ? "small_word_pic_section cursor_pointer" : "small_word_pic_section"} style={h_style} onClick={this.props.action !== "" ? this.props.action : this.emptyFunc}>
                <img src={`${process.env.PUBLIC_URL}/image/`+this.props.img} style={i_style} />
				<div className="half_word_section">
					{this.props.title !== "" ? <h3 style={t_style}>{this.props.title}</h3> : <></>}
					{this.props.content !== "" ? <h4 style={t_style}>{this.props.content}</h4> : <></>}
				</div>
				
			</div>
        );
    }
}

export default SmallWordPicSec;