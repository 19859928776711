import React, { Component } from 'react'

class FiveWItem extends Component {
    
    render() {

        return (
            <div className="five_w_holder" onClick={this.props.action !== "" ? this.props.action : this.emptyFunc}>
                {this.props.title.map((title, i) => (
                    <div key={i} className="five_w_item">
                        <p><span>{title + " > "}</span>{this.props.content[i]}</p>
                    </div>
                ))}
            </div>
        );
    }
}

export default FiveWItem;