import React, { Component } from 'react'

class PicDisplay extends Component {
    
    render() {
        var h_style = this.props.style["holder"];
        var i_style = this.props.style["img"];

        return (
            <div className="pic_section" style={h_style}>
                {this.props.img.map((img_src, index) => 
                <div key={index} className="pic_item" style={i_style}>
                    <div className="img_holder">
                        <img src={process.env.PUBLIC_URL + "/image/" + img_src} />
                    </div>  
                    {this.props.title[index] ? <h3>{this.props.title[index]}</h3> : <></>}
                </div>)}
                
            </div>
        );
    }
}

export default PicDisplay;