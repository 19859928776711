import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Fade from 'react-reveal/Fade';
import Flash from 'react-reveal/Flash';

import { setCookie, getCookie } from '../utils/cookie_utils.js'

import Navbar from '../components/Navbar'
import OpenSlideShow from '../components/OpenSlideShow'
import LogoWall from '../components/LogoWall'
import Footer from '../components/Footer'

import '../css/MainPage.css';
import '../css/NumberAnimation.css';

class MainPage extends Component {
  constructor(props) {
    var lang = "zh"
    if (getCookie("lang") !== "") {
      lang = getCookie("lang")
      console.log("lang in")
    }
    console.log("MainPage after lang:", lang, getCookie("lang"))

    super(props);
    this.state = {
      lang: lang
    }

    this.general_switch_page = this.general_switch_page.bind(this)
    this.update_lang = this.update_lang.bind(this)
  }

  update_lang(current_lang) {
    this.setState({
      lang: current_lang
    }, () => {
      console.log(this.state.lang)
    })
  }

  general_switch_page(path, data) {
    if (path !== "/MainPage" || path !== "/") {
      setCookie("lang", this.state.lang)
      window.location.href = path + "?page=" + data
    }
    else {
      // pass
    }
  }


  render() {

        return (
          <div className={this.state.lang} >
            <Navbar lang={this.state.lang} updateLangFunc={this.update_lang} />
            <OpenSlideShow lang={this.state.lang} switchPageFunc={this.general_switch_page} />
            <MainPageSection1 lang={this.state.lang} switchPageFunc={this.general_switch_page}/>
            <MainPageSection2 lang={this.state.lang} switchPageFunc={this.general_switch_page}/>
            <MainPageSection3 lang={this.state.lang} switchPageFunc={this.general_switch_page}/>
            <LogoWall />
            <Footer lang={this.state.lang} general_switch_page={this.general_switch_page}/>
          </div>
        ) 
  }

}

export default MainPage;

class MainPageSection1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.lang,
      title: {
        "zh": "探索NEXUNI的AI自動化解決方案",
        "en": "Explore NEXUNI's AI Automated Solutions",
        "ja": "NEXUNIのAI自動化ソリューションを探求"
      },
      search_btn: {
        "zh": "開始探索",
        "en": "Start Exploring",
        "ja": "詳しくはこちら"
      },
      content: [{
        delay: "400",
        title: {
          "zh": "整合AI影像分析及邊緣運算實現智慧停車", 
          "en": "Integrate AI Image Analysis and Edge Computing for Smart Parking", 
          "ja": "AI画像分析とエッジコンピューティングを統合してスマートパーキングを実現"},
        content: {
          "zh": "Nexuni整合AI影像分析及硬體邊緣運算將開發智慧停車系統，提高管理效率。我們的系統能實時監控停車場，追蹤佔用率，識別擁堵區域，並利用數據優化運營，如調整價格、引導司機至空車位。智慧停車系統模組亦支援遠程控制、多元支付以及攝像頭影像分析等功能。",
          "en": "Nexuni's smart parking system uses AI image analysis and edge computing for efficient management. It monitors lots in real-time, tracks occupancy, identifies congestion, and optimizes operations like pricing and guiding drivers. The system also supports remote control, multiple payment methods, and camera image analysis.",
          "ja": "Nexuniは、AI画像分析とエッジコンピューティングを活用したスマートパーキングシステムで管理効率を向上させます。リアルタイムで駐車場を監視し、利用率を追跡、混雑エリアを特定し、データに基づいて価格調整やスペースへの誘導を行います。また、リモートコントロール、多様な支払い方法、カメラ画像分析もサポートします。"
        }
      },
      {
        delay: "600",
        title: {
          "zh": "傳統管理數位轉型，透過AI大數據提升設施管理效率", 
          "en": "Enhance Facility Management Efficiency Through AI and Big Data", 
          "ja": "伝統的な管理のデジタル変革、AIとビッグデータによる施設管理の効率化"},
        content: {
          "zh": "Nexuni透過AI大數據轉型傳統設施管理，開發智慧高效的自動化系統。Nexuni開發的自動化設施管理系統包含如影像辨識虛擬巡邏、數位圍籬、人臉識別門禁以及智慧廁所管理系統等。這些系統將可為客戶大幅降低人力依賴，提供詳盡數據，從而有效提升人力資源配置和設施管理效率。",
          "en": "Nexuni leverages AI and big data to develop smart, automated facility management systems. Features include virtual patrols with image recognition, digital fences, facial recognition access control, and smart toilet management. These systems reduce manpower needs, provide detailed data, and improve resource allocation and facility efficiency.",
          "ja": "Nexuniは、AIとビッグデータで伝統的な施設管理を変革し、効率的な自動化システムを開発しています。システムには画像認識による仮想巡回、デジタルフェンス、顔認識アクセスコントロール、スマートトイレ管理が含まれます。これにより、人手依存を大幅に削減し、詳細データを提供して人材配置と管理効率を向上させます。"
        }
      },
      {
        delay: "800",
        title: {
          "zh": "開發AI機器人自動化系統，高效完成重複性任務", 
          "en": "Develop AI Robotic Automation Systems to Efficiently Perform Repetitive Tasks", 
          "ja": "AIロボット自動化システムの開発、繰り返し作業を効率的に実行"},
        content: {
          "zh": "Nexuni為客戶開發客製化AI機器人系統，以提升重複任務的效率。針對各客戶獨特需求，這些系統能在特定領域如半導體或生技工廠中，以機器手臂執行精密作業，或用移動機器人有效協助物品運送，進一步增進工作效能與準確度。",
          "en": "Nexuni develops customized AI robot systems to enhance efficiency in repetitive tasks. These systems, tailored to client needs, use robotic arms and autonomous mobile robots to perform precise tasks in semiconductor and biotech factories, improving work efficiency and accuracy.",
          "ja": "Nexuniは、クライアントのニーズに応じたカスタマイズAIロボットシステムを開発し、反復作業の効率を向上させます。これらのシステムは、半導体やバイオテクノロジー工場でロボットアームや移動ロボットを使用し、精密作業や物品輸送を行うことで、効率と精度を高めます。"
        }
      }
      ]
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lang !== this.state.lang) {
      this.setState({ lang: nextProps.lang });
    }
  }

  render() {

        return (
          <div className="v2_mainpage_section1">
          
            <div className="v2_word_section">

              <Fade duration="1000" delay="200">
                <p className="v2_section_num">01 DISCOVER NEXUNI’S SOLUTIONS</p>
                <h1>{this.state.title[this.state.lang]}</h1>
              </Fade>

              {this.state.content.map((item, index) => 
              <Fade duration="1000" delay={item.delay}>
                <div className="v2_section_content">
                  <h2>{item.title[this.state.lang]}</h2>
                  <p>{item.content[this.state.lang]}</p>
                </div>
                {index === this.state.content.length -1 ? <></> : <hr />}
              </Fade>)}
            </div>
            <Fade duration="1000" delay="1000">
              <div className="v2_img_section" onClick={() => this.props.switchPageFunc("/SolutionPage", "")}>
                <img src="/assets/solutions.png" />
                <h1>{this.state.search_btn[this.state.lang]}</h1>
                <button className="v2_explore_btn">></button>
              </div>
            </Fade>
          </div>
        ) 
  }

}





class MainPageSection3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.lang,
      title: {
        "zh": "與NEXUNI合作增加生產與服務效率",
        "en": "Partner with NEXUNI to enhance productivity and management efficiency of your business",
        "ja": "NEXUNIと提携して、ビジネスの生産性と管理効率を向上させましょう"
      },
      search_btn: {
         "en": "Start Cooperation",
          "zh": "開始合作",
          "ja": "協力を開始する"
      },
      content: [{
        delay: "600",
        title: {
          "zh": "從現有解決方案挑選適合您的企業的AI自動化系統", 
          "en": "Select the AI automation system modules suitable for your business from the existing solutions",
          "ja": "既存のソリューションからあなたのビジネスに適したAI自動化システムを選択します"},
          content: {
          "zh": "選擇適合您企業的AI自動化系統時，您可以從Nexuni提供的多元解決方案中選擇，包括智慧停車、智慧設施管理、AI機器人自動化系統等。根據您的具體需求，這些方案可以被靈活搭配組合，以確保最大程度地滿足您企業的特定需求和目標。",
          "en": "When selecting an AI automation system for your business, you can choose from a variety of solutions offered by Nexuni, including smart parking, smart facility management, AI robotic automation systems, etc. Based on your specific needs, these solutions can be flexibly matched and combined to ensure they meet your business's specific requirements and goals to the fullest.",
          "ja": "あなたのビジネスのAI自動化システムを選ぶ際、Nexuniが提供するスマートパーキング、スマート施設管理、AIロボット自動化システムなど、多様な解決策の中から選ぶことができます。これらのソリューションは、あなたの具体的なニーズに基づいて、マッチングと組み合わせが可能で、ビジネスの特定の要件と目標を最大限に満たすことができます。"
        }
      },
      {
        delay: "800",
        title: {
          "zh": "共同合作開發AI自動化系統解決您企業獨特的痛點", 
          "en": "Partner with NEXUNI to develop customized AI automation systems to address unique challenges of your business",
          "ja": "ビジネスの独自の課題に対応するためにAI自動化システムを共同開発します。"},
          content: {
          "zh": "企業常面臨獨特的運營挑戰，Nexuni可與客戶共同合作，開發專屬的AI自動化系統來解決這些痛點。透過這樣的合作，我們可以精準地對應您的特定需求，打造出符合您企業獨有需求的高效解決方案。",
          "en": "Enterprises often face unique operational challenges, and Nexuni can collaborate with clients to develop customized AI automation systems to address these pain points. Through such collaborations, we can precisely meet your specific needs, creating efficient solutions tailored to the unique requirements of your business.",
          "ja": "企業はしばしば独自の運営上の課題に直面しており、Nexuniはクライアントと協力して、これらの課題に対処するためのカスタムメイドのAI自動化システムを開発することができます。このような協力により、我々はあなたの特定のニーズに正確に対応し、あなたのビジネスのユニークな要求に合わせた効率的な解決策を創り出すことができます。"
        }
      }
      ]
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lang !== this.state.lang) {
      this.setState({ lang: nextProps.lang });
    }
  }

  render() {

        return (
          <div className="v2_mainpage_section2">

            <Fade duration="1000" delay="200">
              <div className="v2_img_section">
                <img src="/assets/mainpage_home3.webp" />
              </div>
            </Fade>
          
            <div className="v2_word_section">

              <Fade duration="1000" delay="400">
                <p className="v2_section_num">03 YOUR JOURNEY WITH NEXUNI</p>
                <h1>{this.state.title[this.state.lang]}</h1>
              </Fade>

              <div className="v2_sub_word_section">
              {this.state.content.map((item, index) => 
                <Fade duration="1000" delay={item.delay}>
                  <div className="v2_section_content">
                    <h2>{item.title[this.state.lang]}</h2>
                    <p>{item.content[this.state.lang]}</p>
                  </div>
                </Fade>
                )}
                

              </div>

              <Fade duration="1000" delay="1000">
                <div className="v2_explore_btn_holder" onClick={() => this.props.switchPageFunc("/GeneralPage", "colab_flow")}>
                  <button className="v2_explore_btn">></button>
                  <p>{this.state.search_btn[this.state.lang]}</p>
                </div>
              </Fade>
            </div>
          </div>
        ) 
  }

}

class MainPageSection2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.lang,
      title: {
        "zh": "NEXUNI的AI自動化解決方案部署案例",
        "en": "NEXUNI's AI Automation Solution Deployment Cases",
        "ja": "NEXUNIのAI 自動化ソリューション導入事例"
      },
      content: [{
        delay: "400",
        title: {
          "zh": "AI智慧停車", 
          "en": "AI Intelligent Parking Systems",
          "ja": "スマートパーキングシステム"},
          content: {
          "zh": "Nexuni的自動化解決方案在智慧停車場領域展現了顯著成效。目前，超過30個停車場和3000多個停車位已經由NEXUNI的智慧停車解決方案管理。這些解決方案包括智慧車位在席偵測系統、AI車牌辨識停車場技術，以及智慧路邊停車開單系統等。這些技術的應用不僅提高了停車效率，也為用戶和管理者帶來了更便捷、準確的服務體驗，從而有效提升了整體停車管理的智慧化水平。",
          "en": "NEXUNI's AI automation solutions have shown remarkable results in the smart parking field. Currently, over 30 parking lots and more than 3,000 parking spaces are managed by NEXUNI's smart parking solutions. These solutions include intelligent parking space occupancy detection systems, AI license plate recognition parking technologies, and smart roadside parking ticketing systems. The application of these technologies has not only improved parking efficiency but also provided a more convenient and accurate service experience for users and managers, thereby significantly enhancing the intelligence level of overall parking management.",
          "ja": "NEXUNIのAI自動化ソリューションはスマートパーキング分野で顕著な成果を上げています。現在、30以上の駐車場と3000以上の駐車スペースがNEXUNIのスマートパーキングソリューションによって管理されています。これらのソリューションには、インテリジェントな駐車スペース占有検出システム、AIナンバープレート認識駐車技術、スマート路上駐車切符システムなどが含まれます。これらの技術の応用により、駐車効率は向上するだけでなく、利用者と管理者により便利で正確なサービス体験を提供し、全体的な駐車管理のインテリジェンスレベルを大幅に高めました。"
        }
      },
      {
        delay: "600",
        title: {
          "zh": "AI智慧設施管理", 
          "en": "AI Intelligent Facility Management",
          "ja": "スマート設備管理"},
          content: {
          "zh": "Nexuni在AI智慧設施管理領域的自動化解決方案成功部署於多個場景。目前，該公司管理著新加坡的13座工廠安保系統和14個智慧廁所。這些解決方案涵蓋了從影像辨識虛擬巡邏、數位圍籬、到人臉識別門禁系統，以及採用AIoT技術的智能廁所管理。這些技術不僅提高了安全性和效率，還為設施管理提供了更加智慧化、自動化的解決方案，有效提升了運營管理的現代化水平，並確保了高效與安全的運作。",
          "en": "NEXUNI's AI automation solutions in the field of smart facility management have been successfully deployed in multiple scenarios. Currently, the company manages security systems for 13 factories in Singapore and 14 smart toilets. These solutions cover everything from image recognition virtual patrols, digital security fences, facial recognition access control systems, to AIoT-enabled smart toilet management. These technologies not only enhance security and efficiency but also provide a more intelligent and automated solution for facility management, effectively raising the digitalization level of operational management and ensuring efficient and safe operations.",
          "ja": "NEXUNIは、スマート設備管理分野でのAI自動化ソリューションを多くのシナリオで成功裏に展開しています。現在、同社はシンガポールの13の工場のセキュリティシステムと14つのスマートトイレを管理しています。これらのソリューションには、ビデオ認識によるバーチャルパトロール、デジタルフェンス、顔認識エントリーシステム、AIoT技術を用いたスマートトイレの管理などが含まれています。これらの技術は、セキュリティと効率を向上させるだけでなく、よりスマートで自動化された設備管理ソリューションを提供し、運営管理の近代化を大いに促進し、効率的かつ安全な運営を確保しています。"
        }
      },
      {
        delay: "800",
        title: {
          "zh": "客製化AI自動化系統開發", 
          "en": "Customized AI Automation System Development",
          "ja": "カスタマイズAI自動化システム開発"},
          content: {
          "zh": "Nexuni提供量身定製的AI自動化系統開發服務，已成功為不同國家的客戶，包括德國、新加坡、美國的半導體和生物技術大廠，進行專屬自動化設備的研發。我們的專業團隊擁有豐富經驗，能夠根據您的具體需求提供最適合的解決方案。如果您對我們的服務感興趣，歡迎聯絡我們，了解更多過去的成功案例，並討論如何為您的企業打造最合適的自動化系統。",
          "en": "NEXUNI offers customized AI automation system development services and has successfully developed specialized automation equipment for clients in different countries, including semiconductor and biotech giants in Germany, Singapore, and the USA. Our professional team, with extensive experience, can provide the most suitable solution based on your specific requirements. If you are interested in our services, please contact us to learn more about our past successful cases and discuss how we can create the most appropriate automation system for your business.",
          "ja": "NEXUNIは、カスタマイズされたAI自動化システム開発サービスを提供しており、ドイツ、シンガポール、アメリカの半導体やバイオテクノロジーの企業を含む、さまざまな国の顧客に特別な自動化装置の研究開発を成功させています。私たちの専門チームは豊富な経験を持ち、お客様の具体的なニーズに合わせて最適なソリューションを提供することができます。私たちのサービスに興味がある場合は、ぜひお問い合わせください。過去の成功事例をさらに知り、お客様の企業に最適な自動化システムの構築方法について話し合いましょう。"
        }
      }
      ],
      num_content: [{
        upto: 30,
        num_id: "num1",
        extra_class: " v2_three_digit",
        title: {
          "zh": "30+個停車場",
          "en": "30+ Parking Lots",
          "ja": "30以上の駐車場"
        }
      },
      {
        upto: 3000,
        num_id: "num2",
        extra_class: " v2_five_digit",
        title: {
          "zh": "管理3000+個車格",
          "en": "Managing 3000+ Parking",
          "ja": "3000以上の駐車スペース管理"
        }
      },
      {
        upto: 13,
        num_id: "num3",
        extra_class: " v2_three_digit",
        title: {
          "zh": "13座新加坡工廠",
          "en": "13 Singapore Factories",
          "ja": "シンガポールの13の工場"
        }
      },
      {
        upto: 14,
        num_id: "num4",
        extra_class: " v2_three_digit",
        title: {
          "zh": "14個智慧廁所",
          "en": "14 Smart Toilets",
          "ja": "14つのスマートトイレ"
        }
      }
      ]
    }


    this.counts = setInterval(this.updatedNumber);

    this.updatedNumber = this.updatedNumber.bind(this);
  }

  updatedNumber(_id, upto) {
    if (_id) {
      let obj = document.getElementById(_id);
      console.log("id: ", _id, upto)
      // count.innerHTML = --upto+1;
      // if (upto === 0) {
      //     clearInterval(this.counts);
      // }

      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / 1000, 1);
        obj.innerHTML = Math.floor(progress * (upto - 0) + 0);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
      }
      
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.lang !== this.state.lang) {
      this.setState({ lang: nextProps.lang });
    }
  }

  render() {

        return (
          <div className="v2_mainpage_section3">

            <Fade duration="1000" delay="1000">
              <div className="v2_number_section">

                {this.state.num_content.map((item, index) => 
                  <div className="v2_number_word_holder">
                    <hr />
                    <h2 className="v2_number_title">{item.title[this.state.lang]}</h2>
                    <div className={"v2_large_number"+item.extra_class}><span id={item.num_id} onMouseEnter={() => this.updatedNumber(item.num_id, item.upto)}>{item.upto}</span>+</div>
                  </div>
                )}

              </div>
            </Fade>
          
            <div className="v2_word_section">

              <Fade duration="1000" delay="200">
                <p className="v2_section_num">02 SUCCESS STORIES FROM OUR CLIENTS</p>
                <h1>{this.state.title[this.state.lang]}</h1>
              </Fade>

              {this.state.content.map((item, index) => 
              <Fade duration="1000" delay={item.delay}>
                <div className="v2_section_content">
                  <h2>{item.title[this.state.lang]}</h2>
                  <p>{item.content[this.state.lang]}</p>
                </div>
                {index === this.state.content.length -1 ? <></> : <hr />}
              </Fade>)}

            </div>
            
            
          </div>
        ) 
  }

}

