import React, { Component } from 'react'

class SelfIntro extends Component {
    
    render() {

        return (
            <div className="intro_section background_color_grey">
                {this.props.content.map((item, index) => <div key={index} className="intro_item">
                    <div className="img_holder" style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/image/" + item["img"]})`}}></div>
                    <div className="intro_item_detail">
                        <h3 className="intro_pic_title">{item["name"]}</h3>
                        <h6 className="intro_pic_sub_title">{item["title"]}</h6>
                    </div>
                </div>)}
                
            </div>
        );
    }
}

export default SelfIntro;