import React, { Component } from 'react'
import ReadMoreReact from 'read-more-react';

class WordSec extends Component {
    
    
    render() {
        var div_style = (this.props.style ? this.props.style["div"] : {});
        var h3_style = (this.props.style ? this.props.style["h3"] : {});
        var h4_style = (this.props.style ? this.props.style["h4"] : {});

        return (
        	
            <div className="word_section">
                {this.props.title.map((title, i) => (
                    <div key={i} style={div_style}>
                        {title !== "" ? <h3 style={h3_style}>{title}</h3>:<></>}
                        {this.props.content[i] !== "" ? <h4 style={h4_style} dangerouslySetInnerHTML={{__html: this.props.content[i]}} />:<></>}
                    </div>
                ))}
                
            </div>
        );
    }
}

export default WordSec;