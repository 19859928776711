import React, { Component } from 'react';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import WordSec from '../components/WordSec'
import WordBtn from '../components/WordBtn'
import ListSec from '../components/ListSec'
import SlideShow from '../components/SlideShow'
import PicWordSec from '../components/PicWordSec'
import WordPicSec from '../components/WordPicSec'
import SelfIntro from '../components/SelfIntro'
import NewsItem from '../components/NewsItem'
import FiveWItem from '../components/FiveWItem'
import PartnerSec from '../components/PartnerSec'
import PicDisplay from '../components/PicDisplay'
import IconWord from '../components/IconWord'
import SmallWordPicSec from '../components/SmallWordPicSec'
import TopPicDownWordSec from '../components/TopPicDownWordSec'
import VideoGallery from '../components/VideoGallery'



import '../css/old_web.css';
import '../css/TechPage.css';
import { setCookie, getCookie } from '../utils/cookie_utils.js'


class TechPage extends Component {

  constructor(props) {
    super(props);

    const searchParams = new URLSearchParams(window.location.search);

    var lang = "zh"
    if (getCookie("lang") !== "") {
      lang = getCookie("lang")
    }

    this.state = {
      id: searchParams.get("page"),
      lang: lang,
      tech_page_icon_flag: true,
      tech_page_flag: false,
      tech_page_title: {"en": [], "zh": []},
      tech_page_desc: {"en": [], "zh": []},
      tech_page_img: [],
      readMoreText: {"en": "Read More", "zh": "閱讀更多", "ja": "続きを読む"},
      web_content: {"": []},
      product: [],
      switch_page_flag: false,
    }

    this.get_data = this.get_data.bind(this)
    this.general_switch_page = this.general_switch_page.bind(this)
    this.update_lang = this.update_lang.bind(this)
  }

  componentDidMount() {
    this.get_data(this.state.id)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lang !== this.state.lang) {
      this.setState({ lang: nextProps.lang });
    }
  }

  update_lang(current_lang) {
    this.setState({
      lang: current_lang
    }, () => {
      console.log(this.state.lang)
    })
  }

  openInNewTab(url) {
        var win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
  }

  general_switch_page(path, data) {
    if (path !== "/GeneralPage" || path !== "/") {
      setCookie("lang", this.state.lang)
      window.location.href = path + "?page=" + data
    }
    else {
      // pass
    }
  }


  render() {

        return (
            <div id="body">
              <Navbar lang={this.state.lang} updateLangFunc={this.update_lang} />

              <div className="tech_section">
                {/*{this.state.tech_page_flag ? <SlideShow lang={this.state.lang} title={this.state.tech_page_title[this.state.lang]} content={this.state.tech_page_desc[this.state.lang]} action={[""]} img={this.state.tech_page_img} img_num={(this.state.tech_page_img).length.toString()} arrow_flag={this.state.tech_page_icon_flag} logo_flag={this.state.tech_page_icon_flag}/> : <></>}*/}
                
                {/*<div className="tech_nav">
                  {this.state.tag[this.state.lang].map((tag, i) => (
                    <button key={i}><a className={this.state.tag["link"][i] === this.state.current_tag ? "clicked" : ""} href={"/TechPage/" + this.props.match.params.id + "/" + this.state.tag["link"][i]}>{tag}</a></button>
                  ))}
                </div>*/}

                <div className="tech_bannar">
                  <img src={"/assets/mainpage_nexuni_white_icon.png"}/>
                  <div className="tech_title_section">
                    <h1>{this.state.tech_page_title[this.state.lang]}</h1>
                    <p>{this.state.tech_page_desc[this.state.lang]}</p>
                  </div>
                </div>

                {Object.keys(this.state.web_content).map((key_name, i) => (
                <div key={i} className={"main_section no_padding_top display_flex"} id={key_name}>
                  {this.state.web_content[key_name].map((item, index) => {
                    if (item["type"] === "tech_intro") {
                      return (<div className="tech_intro">
                          <div className="tech_intro_img" style={{backgroundImage: 'url('+item["img"]+')'}}>
                            
                          </div>
                          <div className="tech_intro_content">
                            <img src={"/assets/mainpage_nexuni_white_icon.png"}/>
                            <h3 dangerouslySetInnerHTML={{__html: item["content"][this.state.lang]}} />
                          </div>
                        </div>)
                    }

                    else if (item["type"] === "tech_cus_and_ps") {
                      return (<div className="tech_cus_and_ps">
                          <div className="tech_cus">
                            <h3>{item["title_cus"][this.state.lang]}</h3>
                            <h4 dangerouslySetInnerHTML={{__html: item["content_cus"][this.state.lang]}} />
                          </div>
                          <div className="tech_ps">
                            <h3>{item["title_ps"][this.state.lang]}</h3>
                            <h4 dangerouslySetInnerHTML={{__html: item["content_ps"][this.state.lang]}} />
                          </div>
                        </div>)
                    }




                    if (item["type"] === "word_section") {
                      return (<WordSec key={index} className="general_section" title={item["title"][this.state.lang]} content={item["content"][this.state.lang]} style={item["style"]} />)
                    }
                    else if (item["type"] === "video_gallery") {
                      return (<VideoGallery key={index} className="general_section" video_link={item["video_link"]} />)
                    }
                    else if (item["type"] === "word_btn") {
                      return (<WordBtn key={index} action={item["action"]} className="general_section" title={item["title"][this.state.lang]} content={item["content"][this.state.lang]} style={item["style"]} />)
                    }
                    else if (item["type"] === "list_section") {
                      return (<ListSec key={index} className="general_section" title={item["title"][this.state.lang]} content={item["content"][this.state.lang]} main_title={item["main_title"][this.state.lang]} />)
                    }

                    else if (item["type"] === "slide_show") {
                      return (<SlideShow lang={this.state.lang} key={index} title={item["title"]} content={item["content"]} action={item["action"]} img={item["img"]} img_num={item["img_num"]} logo_flag={false}/>)
                    }

                    else if (item["type"] === "top_pic_down_word_section") {
                      return (
                        <div key={index} className="two_item_holder">
                          {item["img"].map((img_src, tpdw_i) => 
                            <TopPicDownWordSec key={tpdw_i} img={img_src} title={item["title"][this.state.lang][tpdw_i]} content={item["content"][this.state.lang][tpdw_i]} style={item["style"]}/>
                          )}
                        </div>)
                    }

                    else if (item["type"] === "pic_word_section") {
                      var rmtxt = "";
                      if (!Object.values(item).includes("readMoreText")) {
                        rmtxt = item["content"][this.state.lang].length > 300 ? this.state.readMoreText[this.state.lang] : "";
                      }
                      return (<WordPicSec key={index} className="general_section" readMoreText={rmtxt} img={item["img"]} title={item["title"][this.state.lang]} content={item["content"][this.state.lang]} style={item["style"]}/>)
                    }

                    else if (item["type"] === "word_pic_section") {
                      var rmtxt = "";
                      if (!Object.values(item).includes("readMoreText")) {
                        rmtxt = item["content"][this.state.lang].length > 300 ? this.state.readMoreText[this.state.lang] : "";
                      }
                      return (<PicWordSec key={index} className="general_section" readMoreText={rmtxt} img={item["img"]} title={item["title"][this.state.lang]} content={item["content"][this.state.lang]} style={item["style"]}/>)
                    }

                    else if (item["type"] === "small_word_pic_section") {
                      return (<SmallWordPicSec key={index} action={item["action"]} className="general_section" img={item["img"]} title={item["title"][this.state.lang]} content={item["content"][this.state.lang]} style={item["style"]}/>)
                    }

                    else if (item["type"] === "self_intro") {
                      return (<SelfIntro key={index} className="general_section" content={item["content"]} />)
                    }

                    else if (item["type"] === "news_item") {
                      return (<NewsItem key={index} className="general_section" content={item["content"]} />)
                    }

                    else if (item["type"] === "five_w_item") {
                      return (<FiveWItem key={index} className="general_section" title={item["title"][this.state.lang]} content={item["content"][this.state.lang]} />)
                    }

                    else if (item["type"] === "title_section") {
                      return (<div key={index} className="title_section_holder"><h1>{item["title"][this.state.lang]}</h1></div>)
                    }

                    else if (item["type"] === "partner_section") {
                      return (<PartnerSec key={index} img={item["img"]} title={item["title"][this.state.lang]} action={item["action"]}/>)
                    }

                    else if (item["type"] === "pic_display") {
                      return (<PicDisplay key={index} img={item["img"]} title={item["title"][this.state.lang]} style={item["style"]}/>)
                    }

                    else if (item["type"] === "video_display") {
                      return (<iframe key={index} width="100%" height="700px" src={item["video_src"]}></iframe>)
                    }

                    else if (item["type"] === "icon_word") {
                      return (<div key={index}  className="icon_word_holder">
                                {item["title"][this.state.lang].map((title, i) => (<IconWord key={i} style={item["style"]} img={item["img"][i]} title={title}/>))}
                              </div>)
                    }

                  })}
                </div>))}


              </div>
              

              <Footer lang={this.state.lang} general_switch_page={this.general_switch_page} />
      
            </div>
      
        );}



  get_data(page_title) {
    const tech_content = {
      
      // "parking": {
      //   "tech_page_title": {"en": ["Intelligent parking system"], "zh": ["智慧停車系統"]},
      //   "tech_page_desc": {"en": [""], 
      //                     "zh": [""]},
      //   "tech_page_img": ["carpark_real_photos/car_park_1-1.jpg"],
      //   "tech_page_icon_flag": true,
      //   "tag": {"en": ["Intelligent parking system", "VPGS", "Low Power Wireless Roadside Parking System", "Service Points"], "zh": ["智慧停車系統", "智慧車位導引系統", "低功耗無線路邊停車柱", "服務據點"], "link": ["ips", "vpgs", "pp", "sp"]},
      //   "web_content": {
      //     "sp": [{
      //       "type": "map_display",
      //       "file_name": "/place.json",
      //       "icon": "/image/carday_map_icon.png",
      //       "gmap_key": "AIzaSyCTGB-224d3w1n64yJ8WuBrCBBVx_xJJqA"
      //     }],
      //     "ips": [{
      //       "type": "title_section",
      //       "title": {"en": "This is an intelligent and efficient management system developed for parking lot operators. It not only relieves the burden of human management but also provides more data for analysis and management through our NXAI-enabled system.", 
      //                 "zh": "為停車場業者所研發的省力、智慧有效率的管理系統，不但省去了人力管理負擔，更以科技化的設備提供更多的資料供分析管理使用"},
      //     },{
      //       "type": "pic_word_section",
      //       "style": {"text": {}, "img": {}},
      //       "title": {
      //         "en": "",
      //         "zh": ""},
      //       "content": {
      //         "en": "Nexuni can propose different solutions for different parking environments. Our parking lot system Nexuni can be roughly divided into several types: Intelligent parking system based on license plate recognition, Intelligent parking system based on wireless tag recognition (such as ETag), embedded parking system suitable for apartment parking management or small land area, and electric vehicle charging management system. Our solutions integrate the necessary equipment, core technology, and backend automation management for parking lot management.",
      //         "zh": "Nexuni可針對不同停車場域提出不同的解決方案。Nexuni的停車場系統可大致分為以下幾種：以車牌辨識為基礎的智慧影像辨識停車系統、以無線訊號(如ETag)為基礎的智慧停車系統，適合公寓社區或小型場域的微型停車系統，以及電動車的充電管理系統。我們提供的解決方案整合停車場基本所需之設備、核心技術和後端自動化管理。"},
      //       "img": ["main_parq.png"]
      //     },{
      //       "type": "word_pic_section",
      //       "style": {"text": {}, "img": {padding: '60px 0'}},
      //       "title": {
      //         "en": "Intelligent Parking System based on LPR",
      //         "zh": "智慧車牌辨識停車管理系統"},
      //       "content": {
      //         "en": "The system architecture is simple and easy to install. This license plate recognition (LPR) technology utilizes deep machine learning technology, enabling highly accurate recognition of license plates and possessing high fault-tolerance, minimizing occurrences of license plate recognition failures. The system also supports complete electronic payment and e-invoicing functionalities. Additionally, a remote web management system is provided for users to perform management tasks. The core of this system lies in its powerful and precise AI-based license plate recognition technology. Leveraging AI technology, this system can also include features such as parking guidance systems, automated payment machines, and online payment of parking fees.",
      //         "zh": "系統架構簡單，易於安裝。此車牌辨識系統採用深度機器學習技術，能夠高度準確地辨識車牌，並具備高度容錯能力，不易出現車牌辨識卡頓現象。此系統也支援完整的電子支付及電子發票功能。此外，遠端網頁管理系統也提供給使用者進行管理。這個系統的核心在於強大且準確的AI/車牌辨識科技。藉由AI科技，這個系統也可以選擇包含停車引導系統功能,自動化收款機器,線上繳停車費等等功能。<a href='/image/flyer1002.pdf'>點此查看更多</a>"},
      //       "img": ["ips.jpg"]
      //     },{
      //       "type": "icon_word",
      //       "style": {"text": {}, "img": {}},
      //       "title": {
      //         "en": [
      //           "99.5% accuracy plate recognition correctness", 
      //           "150 ms recognition process time", 
      //           "AI continuous learning and data analysis"],
      //         "zh": [
      //           "99.5%車牌辨識準確率", 
      //           "150毫秒辨識時間", 
      //           "利用AI持續學習"]},
      //       "img": ["parking-1.png", "parking-2.png", "parking-3.png"]
      //     },{
      //       "type": "word_pic_section",
      //       "style": {"text": {}, "img": {padding: '60px 0 0 0'}},
      //       "title": {
      //         "en": "Intelligent Parking System based on Wireless Tag (ETag) Recognition",
      //         "zh": "無線識別(ETag)停車管理系統"},
      //       "content": {
      //         "en": "The wireless identification parking management system utilizes wireless identification tags, such as Taiwan's ETag and Singapore's In-vehicle Unit (IU), for entry and exit management of parking. Wireless identification provides an alternative identification method beyond visual recognition, but it is necessary to ensure that each vehicle is equipped with the wireless identification device. The Nexuni team of experts has extensive experience in the development of wireless identification parking management systems, having installed over 1000 systems in Singapore and Taiwan. To learn more about the details of wireless identification parking management systems, please contact us.",
      //         "zh": "無線識別停車管理系統使用無線識別標籤，如台灣的ETag以及新加坡的In-vehicle Unit (IU)，來進行停車進出場管理。使用無線識別可提供影像以外的另一種辨識方式，但必須確保每一台車輛均配備此無線識別裝置。Nexuni團隊的專家對無線識別停車管理系統的開發具備相當豐富的經驗，在新加坡及台灣已經裝配超過1000個以上的系統，若想了解更多無線識別停車管理系統的細節，請與我們聯絡。"},
      //       "img": ["eps.jpg"]
      //     },{
      //       "type": "pic_word_section",
      //       "style": {"text": {}, "img": {padding: '60px 0 0 0'}},
      //       "title": {
      //         "en": "Embedded Parking System",
      //         "zh": "微型停車系統"},
      //       "content": {
      //         "en": "This system employs an embedded smart camera and integrates with the NXAI framework for license plate recognition. With its highly integrated architecture, it can be easily DIY-installed. The machine learning license plate recognition technology achieves a 99.5% accuracy rate and has a small form factor with high expandability, making it suitable for various parking needs. The system supports multiple payment systems and has a remote web management system. Due to its small size and easy installation, this system is ideal for managing parking needs in small-scale areas and residential buildings such as apartment communities.<a href='/image/carparkmini_en.pdf'>show more info</a>",
      //         "zh": "此系統使用嵌入式智慧攝影機，並結合NXAI框架進行車牌辨識。它具有高度整合架構，可方便地DIY安裝。機器學習車牌辨識技術可達到99.5%的準確度，體積小且擴充性高，適用於各種停車需求。此系統支援多種支付系統，並且具有遠端網頁管理系統。因爲此系統的體積小且安裝方便，所以非常適合小型的場域以及公寓社區大樓等停車需求管理。<a href='/image/carparkmini_zh.pdf'>點此查看更多</a>"},
      //       "img": ["cm.png"]
      //     },{
      //       "type": "word_pic_section",
      //       "style": {"text": {}, "img": {padding: '60px 0 0 0'}},
      //       "title": {
      //         "en": "Electric Vehicle (EV) charging management system",
      //         "zh": "智慧型車辨充電柱"},
      //       "content": {
      //         "en": "The Smart EV Charging Station integrates electric vehicle charging and license plate recognition functions, and is highly integrated with parking management systems, providing a comfortable and unhurried charging environment. In addition, it features an intelligent power distributor, which can intelligently distribute the charging current to multiple EVs. With its high accuracy deep machine learning license plate recognition, it provides efficient vehicle identification and management. It also integrates with parking payment systems to offer a variety of charging fee modes, making payment convenient for users. With the remote web-based management system, users can easily reserve charging and manage electricity usage, providing more convenient and efficient services. This compact and highly scalable Smart EV Charging Station is suitable for various parking needs, and is an essential device for modern parking facilities.<a href='/image/carparkelectric_en.pdf'>show more info</a>",
      //         "zh": "智慧型車辨充電柱整合了電動車充電功能和車牌辨識功能，並且與停車管理系統高度整合，提供舒適不匆忙的充電環境。此外，它還擁有智能電源分配器，可安裝最大數量的充電系統。其深度機器學習車牌辨識準確度高，可提供高效的車輛識別和管理。此外，它還可以整合停車場支付系統，以提供多樣化的充電收費模式，方便使用者進行支付。透過遠端網頁管理系統，使用者可以輕鬆預約充電和管理用電，提供更方便、快捷的服務。這種智慧型車辨充電柱體積小，且擴充性高，適用於各種停車需求，是現代化停車場必備的設備之一。<a href='/image/carparkelectric_zh.pdf'>點此查看更多</a>"},
      //       "img": ["pp.png"]
      //     },{
      //       "type": "pic_word_section",
      //       "style": {"text": {}, "img": {padding: '60px 0 0 0'}},
      //       "title": {
      //         "en": "Parking System Backend Management",
      //         "zh": "後端管理自動化"},
      //       "content": {
      //         "en": "While most of the parking lots we see are unmanned on site, there are still many things to track behind the scene when it comes to parking lot operation. Tasks such as season parking registration, settlement, and device management are still usually done by humans. Nexuni provides backend management software to help parking lot operators manage all these aspects and customize based on actual needs, for example, electric car vehicle charging quota management.",
      //         "zh": "我們所見大多數的停車場現場皆無工作人員，但實際上在後端仍然需要追蹤許多現場狀況才能有效地管理停車場。像是月票登記,設備裝設和管理大多時候還是由人力所完成。Nexuni提供了後端管理的軟體來幫助停車場營運和管理這些層面，同時可以根據停車場真實的需求來客製化軟體，舉例來說，電動車的充電管理系統就是一個好的例子。"},
      //       "img": ["carparkgui.png"]
      //     },{
      //       "type": "video_display",
      //       "video_src": "https://www.youtube.com/embed/SET_vlfzTcY"
      //     }],
      //     "vpgs": [{
      //         "type": "title_section",
      //         "title": {
      //           "en": ["Nexuni proposes an image-based smart parking guidance system, which integrates the NXAI artificial intelligence model into an embedded camera module to achieve lightweight and high-precision parking detection. The Video-based Parking Guidance System (VPGS) can be further integrated with parking management systems to provide real-time information on parking space availability and guidance, providing more data for parking-related analysis."],
      //           "zh": ["Nexuni提出使用影像為基礎的智慧車位導引系統，並結合NXAI將人工智慧模型引入嵌入式的攝影機模組當中來達到輕量且高精準度的車位在席感測。此智慧車位導引系統 (VPGS)可更進一步整合停車管理系統來提供即時車位數量、空位導引等資訊，來提供更多停車場相關的資料分析。"]}
      //       },{
      //       "type": "word_pic_section",
      //       "style": {"text": {}, "img": {padding: '60px 0 0 0'}},
      //       "img": ["pgs.png"],
      //       "content": {
      //         "en": ["Traditional parking guidance systems (PGS) often use technologies such as ultrasound and infrared to determine whether a vehicle is in a parking space. This method often leads to errors and causes parking lot managers to underestimate the actual number of parking spaces, thereby reducing the accuracy of parking guidance. Nexuni's Video-based Parking Guidance System (VPGS) uses images and applies the NXAI framework to a microprocessor, which is small in size and easy to install. It uses a license plate recognition model for edge computing to accurately determine the occupancy status of parking spaces and can provide more image data for parking lot management analysis.\n\n\n\nThe VPGS is not only easy to install, but also has a high license plate recognition rate, can accurately display real-time parking space status, and can be upgraded to include the Find-My-Car function. The VPGS hardware is simple and inexpensive, requiring only one power cable to handle a row of devices without the need for additional wiring slots. It only requires two screws to install. The system uses microprocessors to reduce hardware costs and incorporates edge computing instead of rack-mounted servers, resulting in lower electricity costs. Because it uses image sensing, the system can collect and analyze parking lot usage data and continuously learn from photos of the same area to make more accurate judgments and responses. Currently, Nexuni's Video-based Parking Guidance System (VPGS) has been installed and used in venues in Singapore and Taiwan, and we welcome more partners to contact us for collaboration."],
      //         "zh": ["傳統的車位導引系統 (PGS)多透過超音波、紅外線等技術去判斷車輛是否在車位中。此方法常常會導致誤差並使停車場管理錯估實際的車位數量，進而降低車位導引的準確度。Nexuni提出的智慧車位導引系統 (VPGS)使用影像並將NXAI框架套用在微處理器上，體積小且安裝方便，使用車牌辨識模型進行邊緣運算，可準確的判斷車位在席狀態，且可提供更多影像資料進行停車場管理分析。\n\n\n\n智慧車位導引系統 (VPGS)不僅安裝方便，而且車牌識別率高，能準確顯示即時停車位狀態，同時還可升級加入智慧尋車功能。VPGS硬體簡單，成本低廉，無需額外的線槽，一條電線即可處理一排設備，僅需兩顆螺絲即可安裝。此系統使用微處理器降低硬體成本，同時使用邊緣運算而非機架式伺服器，使得電費成本更低。因為其使用影像感測，所以系統能夠收集和分析停車場的使用數據，並持續自我學習相同場域的照片，以更精確地判斷和反應情況。目前，Nexuni的智慧車位導引系統已在新加坡和台灣的場地安裝使用，也歡迎更多的合作夥伴與我們聯繫。"]},
      //       "title": {
      //         "en": ["Video-based Parking Guidance System (VPGS)"],
      //         "zh": ["智慧車位導引系統 (VPGS)"]}
      //     },{
      //       "type": "video_display",
      //       "video_src": "https://www.youtube.com/embed/95tkMefrVKw"
      //     }],
      //     "pp": [{
      //       "type": "title_section",
      //       "title": {
      //         "en": ["We introduce a low-power, wireless roadside parking system to address the drawbacks of existing roadside parking poles in the market, such as difficult installation, wiring requirements, and the need for frequent battery replacement. The low-power wireless roadside parking pole can perform license plate recognition on low-power devices and upload the results to the cloud, providing an end-to-end roadside parking system."],
      //         "zh": ["此產品改善了市場上現有路邊停車柱的痛點：安裝困難、需佈線或時常更換電池，並提出一個低功耗且無線的路邊停車設備。低功耗無線路邊停車柱可在低功耗的裝置上進行車牌辨識並將結果上傳至雲端，提供一個端到端的路邊停車系統。"]}
      //     },{
      //       "type": "word_pic_section",
      //       "style": {"text": {}, "img": {padding: '60px 0 0 0'}},
      //       "title": {
      //         "en": "",
      //         "zh": ""},
      //       "content": {
      //         "en": "Nexuni's low-power wireless roadside parking pole integrates the NXAl framework. In addition to being able to recognize license plates on low-power devices, its built-in microprocessor and camera can be self-powered by batteries and can be expanded to use solar energy for self-charging. Installation of this product does not require wiring or network cables. It can be easily installed with four screws. Edge computing using this technology enables real-time monitoring of parking status, parking space management, and parking lot scheduling features. This wireless roadside parking pole uses CAT-M1 technology to wirelessly transmit roadside parking information and strengthen parking space management functions. Nexuni has already developed pole-type and ground-type parking poles in Taiwan and please contact us for further collaboration. ",
      //         "zh": "Nexuni提出的低功耗無線路邊停車柱整合NXAl框架，除了可在低功耗的裝置上辨識車牌外，其搭載的微處理器以及攝相機可用電池自我供電並可擴充使用太陽能自行充電。此產品的安裝不需佈電線和網路線。四個釘子即可安裝方便簡單! 以此技術進行邊緣運算可以實現對停車狀態實時監測、停車位的管理和調度等功能。此無線路邊停車柱使用CAT-M1技術無線傳輸路邊停車資訊並強化車位管理功能。目前Nexuni已在台灣開發柱形與地面型停車柱，歡迎有興趣的合作夥伴聯絡我們。"},
      //       "img": ["pp.png"]
      //     },{
      //       "type": "video_display",
      //       "video_src": "https://www.youtube.com/embed/iyMhOVPEv0g"
      //     }]
      //   }
      // },
      // "nex": {
      //   "tech_page_title": {"en": ["Intelligent Management System"], "zh": ["智慧管理系統"]},
      //   "tech_page_desc": {"en": [""], 
      //                     "zh": [""]},
      //   "tech_page_img": ["home3.jpg"],
      //   "tech_page_icon_flag": true,
      //   "tag": {"en": ["NexShop - Intelligent Store Management", "NexControl - Intelligent Factory Management", "NexSupport - AI Customer Support Solution", "NexSense - Intelligent Toilet Management"], "zh": ["NexShop - 智慧店舖管理", "NexControl - 工廠自動化管理", "NexSupport - AI智慧客服", "NexSense - 智慧廁所管理"], "link": ["nexshop", "nexcontrol", "nexsupport", "nexsense"]},
      //   "web_content": {
      //     "nexshop": [
      //       {
      //         "type": "title_section",
      //         "title": {"en": "\"When the traditional cuisines meet new technologies\" perfectly explains the automated store solution. We developed a solution to automate ordering, payment, cooking, and delivery within the store operation, which reduces up to 50% of labor and management cost.", 
      //                   "zh": "結合自動化的技術，堅持健康安心的理念，致力於打造一間傳統與科技結合的美味食品店"},
      //       },{
      //         "type": "pic_word_section",
      //         "style": {"text": {}, "img": {}},
      //         "title": {
      //           "en": "",
      //           "zh": ""},
      //         "content": {
      //           "en": "Nexuni aims to develop a turn-key automated store solution to maximize the values of limited human resources. This solution can be categorized into four categories: ordering/payment automation, kitchen automation, food-serving automation, and store management automation. ",
      //           "zh": "Nexuni致力於打造一套完整的自動化商店系統，在有限的人力下達到最佳營運狀態。我們將這個系統分為四個方向：點餐付款自動化、廚房自動化、送餐自動化與店面管理自動化"},
      //         "img": ["main_doday.png"]
      //       },{
      //         "type": "icon_word",
      //         "style": {"text": {}, "img": {}},
      //         "title": {
      //           "en": ["Keep track of all your orders and payment digitally", "Standardize your recipe and the taste", "Accurate and contactless serving", "Let AI help you keep track of everything in the store"],
      //           "zh": ["將您的訂單與付款紀錄存入系統", "將您的菜單與料理標準化", "非接觸式與無錯服務", "人工智慧替您掌握店內一切"]},
      //         "img": ["d_logo_1.png", "d_logo_2.png", "d_logo_3.png", "d_logo_4.png"]
      //       },{
      //         "type": "pic_word_section",
      //         "style": {"text": {}, "img": {}},
      //         "title": {
      //           "en": "Ordering / Payment Automation",
      //           "zh": "點餐付款自動化"},
      //         "content": {
      //           "en": "We have it all: self-ordering kiosk, POS machine, online payment integration, online ordering app, contactless payment. Whatever equipment you need to take orders or get paid for your store, we have solutions. It is estimated that this solution can save you up to 50% of your total labor costs. Contact us to know more!",
      //           "zh": "我們擁有自動點餐機、POS機、線上付款模組、線上點餐、非接觸式付款，能符合您接單或付款的需求。數據顯示這些功能能讓您省下到50\%的人力需求，歡迎聯絡我們得到更多資訊。"},
      //         "img": ["order_machine.png"]
      //       },{
      //         "type": "top_pic_down_word_section",
      //         "style": {"img": {}, "text": {}},
      //         "title": {
      //           "en": ["Kitchen Automation", "Food-Serving Automation"],
      //           "zh": ["廚房自動化", "出餐送餐自動化"]},
      //         "content": {
      //           "en": ["The kitchen is the core of any F&B business. Food handling and cooking procedures vary store by store and would need to be discussed more in-depth to provide you a unique solution. For example, the kitchen automation solution for our pilot store - DoDay, consists of automated cooking pots, automated boba cookers, an IOT bean soaking device, etc. We connect our patented technologies and kitchen appliances to provide our client a total solution that helps standardize the quality and tastes at a minimal effort.",
      //                 "Large restaurants or F&B businesses that allow for a high degree of customization might find it challenging to keep track of all order details. Nexuni provided our clients with solutions to build up their in-restaurant delivery network based on our food-delivery robots. Moreover, simple food dispatch and packaging automation solutions can also be provided by request. Contact us if you are interested!"
      //                 ],
      //           "zh": ["餐飲行業最核心的部分即在廚房，因此我們需要深入地與客戶討論客製化的方案，以符合餐點準備與製作流程。以豆日子本店為例，我們量身打造了自動化的鍋子、珍珠機與智慧浸泡豆子裝置。Nexuni結合專利技術與廚房智慧裝置，在最小的成本下為客戶打造標準化的流程，確保食物的品質與美味。",
      //                 "很多餐廳與餐飲業認為高程度的自動化或許會很難確保餐點的所有細節。Nexuni提供客戶基於食物運送機器人技術的餐廳內送餐系統，除此之外，也提供簡單的食物分配與封裝功能。如果您有興趣的話請聯繫我們！"
      //                 ]},
      //         "img": ["tech_doday3.jpg", "beans.gif"]
      //       },{
      //         "type": "word_pic_section",
      //         "style": {"text": {}, "img": {}},
      //         "title": {
      //           "en": "Store Management Automation",
      //           "zh": "店面管理自動化"},
      //         "content": {
      //           "en": "What else does running a business involve? Surprisingly, there’s still a lot to keep track of, for example, inventory, payroll, finance, taxes, work-shift schedule, and, if you prefer, the membership program of your brand. In Nexuni’s total automated store solution, we cover all of these for you. We have developed cloud applications that connect local stores so that all the information can be tracked and stored on the cloud. If you have particular thoughts or features that you aren’t sure we provide, talk with us and let’s make it happen. ",
      //           "zh": "經營一項事業還需要什麼呢？有許多事項是需要注意的，舉例來說，庫存管理、薪資管理、財務管理、稅務管理、班表管理、會員管理。在Nexuni整體自動化庫存方案中，我們為你處理了這些層面。我們開發了雲端APP來連接當地商店的設備，以便所有資訊可以即時被確認和儲存到雲端。如果你有特別的想法或是對於我們的商品有疑惑，歡迎和我們聯繫讓您的想法成真！"},
      //         "img": ["store_management.png"]
      //       },{
      //         "type": "slide_show",
      //         "action": [""],
      //         "title": [""],
      //         "content": [""],
      //         "img_num": "1",
      //         "img": ["doday_extra.png"]
      //       },{
      //         "type": "video_display",
      //         "video_src": "https://www.youtube.com/embed/6Shcu-qJTWM"
      //       }
      //     ],
      //     "nexcontrol": [
      //       {
      //         "type": "title_section",
      //         "title": {"en": "The automation of Industry 4.0 is poised to significantly enhance semiconductor factory productivity, and Nexuni offers a robotic process automation (RPA) solution for factory automation.", 
      //                   "zh": "工業4.0自動化將會大幅提升半導體工廠的生產效率，Nexuni針對工廠自動化提供機器流程自動化（RPA）解決方案"},
      //       },{
      //         "type": "pic_word_section",
      //         "style": {"text": {}, "img": {}},
      //         "title": {
      //           "en": "",
      //           "zh": ""},
      //         "content": {
      //           "en": "Nexuni proposes NexControl, which uses remote management technology to control factory machinery, preventing machines from idling or malfunctioning. It is compatible with various older and different-brand machine controls. Moreover, in terms of information security, it does not require any network access. By simply installing NexControl hardware and operating the computer via NexControl, it can guarantee that no information will be exposed to third parties. NexControl features plug-and-play and remote management capabilities, coupled with web-based management and real-time AI recognition, making this solution perfectly suited to practical needs. The system has been validated in semiconductor factories in Taiwan.",
      //           "zh": "Nexuni提出NexControl，使用遠端管理技術控制工廠機台，避免機台進入閒置或是出錯。可兼容各種舊式、 不同廠商的機台控制。此外，在資訊安全上則不需透過任何網路，只需安裝NexControl 硬體，藉由NexControl操作電腦，可保證 任何資訊不會外露給第三方。NexControl具有即插即用、遠端管理的特性，再搭配上網頁管理以及AI的即時辨識，使得此方案可以最大限度的迎合實務上的需求。此系統目前已在台灣半導體廠驗證使用。"},
      //         "img": ["tech_008_main.jpg"]
      //       },{
      //         "type": "title_section",
      //         "title": {"en": "NexControl Flow", 
      //                   "zh": "NexControl半導體工廠自動化流程"},
      //       },{
      //         "type": "icon_word",
      //         "style": {"text": {}, "img": {}},
      //         "title": {
      //           "en": ["Remote control of semiconductor machines", "Analysis of screen data and cloud-based uploading", "Equipping with AI to provide real-time responses to machine status", "Integration of modules such as autonomous robot and robotic arms to achieve full Industrial 4.0 factory automation."],
      //           "zh": ["遠端控制半導體機台", "分析螢幕數據並上傳雲端", "搭載AI並即時針對機台狀態作出回應", "可整合自走車、機械手臂等模組以達到完全的工廠自動化"]},
      //         "img": ["s_logo_1.png", "s_logo_2.png", "s_logo_3.png", "s_logo_4.png"]
      //       },{
      //         "type": "title_section",
      //         "title": {"en": "NexControl Advantages", 
      //                   "zh": "NexControl優勢"},
      //       },{
      //         "type": "small_word_pic_section",
      //         "style": {"div": {width: '80%'}, "h3": {}, "h4": {}},
      //         "img": "semi_logo_2.png",
      //         "content": {
      //           "en": ["NexControl offers a control method that can be used regardless of the target interface, with no need for software installation. This feature provides unparalleled convenience and ease of use."],
      //           "zh": ["我們提供一個無論控制目標的操作介面為何皆可使用的控制方法，不需安裝任何軟體的特性也使得應用起來更加便利。"]},
      //         "title": {
      //           "en": ["Plug-and-Play Functionality"],
      //           "zh": ["即插即用"]}
      //       },{
      //         "type": "small_word_pic_section",
      //         "style": {"div": {width: '80%'}, "h3": {}, "h4": {}},
      //         "img": "semi_logo_1.png",
      //         "content": {
      //           "en": ["Our web-based control interface allows you to operate NexControl from any device, providing you with flexible and convenient control capabilities."],
      //           "zh": ["我們提供網頁型態的控制界面，使你可以在任意種類的裝置上進行控制。"]},
      //         "title": {
      //           "en": ["Web-Based Control"],
      //           "zh": ["網頁控制"]}
      //       },{
      //         "type": "small_word_pic_section",
      //         "style": {"div": {width: '80%'}, "h3": {}, "h4": {}},
      //         "img": "semi_logo_4.png",
      //         "content": {
      //           "en": ["By consolidating signals from multiple machines, NexControl enables administrators to monitor the status of numerous devices simultaneously, thus streamlining management processes and reducing personnel requirements."],
      //           "zh": ["我們透過將多台的信號回傳到中央，讓管理員可以一次性看到多台機器的狀態，使得管控更加方便，且能更進一步地減少管理所需的人力。"]},
      //         "title": {
      //           "en": ["Centralized Management"],
      //           "zh": ["中央控管"]}
      //       },{
      //         "type": "small_word_pic_section",
      //         "style": {"div": {width: '80%'}, "h3": {}, "h4": {}},
      //         "img": "semi_logo_3.png",
      //         "content": {
      //           "en": ["NexControl is compatible with artificial intelligence algorithms that can analyze screen data for automatic control. This added capability enables comprehensive automation and enhanced control functionalities."],
      //           "zh": ["可以搭配人工智能演算法，偵測螢幕上的數據進行分析，以達到完整的自動控制。"]},
      //         "title": {
      //           "en": ["AI Compatibility"],
      //           "zh": ["可搭配AI"]}
      //       }
      //     ],
      //     "nexsupport": [
      //       {
      //         "type": "title_section",
      //         "title": {"en": "NexSupport combines AI voice recognition and big data analysis of common questions and answers to achieve intelligent customer support. The AI customer support solution uses edge computing, which is not limited by the network and can answer and solve customer problems on the spot. In addition, the model can become smarter by setting and providing more content.", 
      //                   "zh": "NexSupport結合AI語音辨識以及大數據統計常見問答來達到智慧回應的語音客服。智能客服採用邊緣運算，不受網路限制， 可以當場回答、並解決顧客問題。此外也 可以透過設定以及提供更多內容讓模型越變越聰明。"},
      //       },{
      //         "type": "pic_word_section",
      //         "style": {"text": {}, "img": {}},
      //         "title": {
      //           "en": "",
      //           "zh": ""},
      //         "content": {
      //           "en": "No matter how stable the automation equipment is, there are still situations in the management of various facilities in smart cities where users need to call customer service. In our experience, these problems are often repetitive, so Nexuni hopes to use AI and big data analysis to learn these questions and establish the NexSupport intelligent customer support system to assist users in answering common questions. This system can continue to learn and evolve, and can handle complex sentences. Currently, it can immediately solve about 80% of specific customer issues. In the future, it will continue to learn new questions and handle different situations more intelligently. NexSupport is currently mainly deployed in parking lots and is being validated in Taiwan's parking lot customer support system, with the goal of increasing customer service efficiency and quality.",
      //           "zh": "不管自動化設備做的多麽穩定，在智慧城市各個設施的管理當中不時還是會出現需要使用者打電話給客服的狀況。據我們的經驗，這些問題其實常常都是重複的問題，於是Nexuni便希望透過AI以及大數據統計去學習這些問題並建立NexSupport智慧客服系統來協助用戶解答一些常見的問題。此系統可以持續學習進化，並且處理複雜化的語句，目前已可馬上解決特定範圍約 80%客人問題。 未來會持續學習新的問題，更聰明地處理不同情境。NexSupport目前先以停車場為主進行部署，在台灣停車場的客服系統進行驗證，目標是增加客服效率與品質。"},
      //         "img": ["nexsupport.png"]
      //       },{
      //         "type": "video_display",
      //         "video_src": "https://www.youtube.com/embed/gS7BCgGx88Q"
      //       }
      //     ],
      //     "nexsense": [
      //       {
      //         "type": "title_section",
      //         "title": {"en": "NexSense integrates intelligent monitoring and management technologies, and applies them to the field of toilet management. This system uses AIoT, Cat-M1, and other technologies to instantly transmit sensor data to the backend, allowing managers to immediately understand the status of various aspects of the restroom (such as toilet paper and cleaning fluid availability), which not only improves manpower allocation efficiency but also enhances management quality.", 
      //                   "zh": "NexSense整合了智慧監測以及智慧管理並應用在廁所管理的領域。此系統採用AIoT、Cat-M1等技術，即時把感測器的數值傳至後台，使管理者能馬上了解廁所各項狀況（如衛生紙、清潔液庫存狀態），此舉除了可增進人力分配效率外，也提升管理品質。"},
      //       },{
      //         "type": "pic_word_section",
      //         "style": {"text": {}, "img": {}},
      //         "title": {
      //           "en": "",
      //           "zh": ""},
      //         "content": {
      //           "en": "NexSense includes a set of people count monitoring systems, which monitor the number of people entering / exiting the restroom through the camera installed at the entrance and exit of the restroom, reflecting the cleanliness and hygiene conditions. This data can help managers better plan regular cleaning and maintenance work. In order to improve cleaning efficiency and service quality, NexSense can notify cleaning staff of consumables that need to be replaced through sensor data. This saves the cleaning staff from having to check the inventory of various items one by one. In addition, it reduces the time when consumables such as hand soap are insufficient, enhancing the quality of the restroom.",
      //           "zh": "NexSense包含一套人流監測系統，其透過在廁所出入口安裝攝像機來監測人流量，進而反應清潔度和衛生情況。這些數據可以幫助管理者更好地規劃定期清潔和維護工作。為求提高清潔效率及提升服務品質，NexSense可透過感測器數值通知清潔員需要更換的消耗品。此舉讓廁所的清潔員不需一一檢查各項物品的庫存。此外，也減少消耗品如洗手液不足的時間，提升廁所品質。"},
      //         "img": ["nexsense.png"]
      //       },{
      //         "type": "video_display",
      //         "video_src": "https://www.youtube.com/embed/Nno6nnffuMw"
      //       }
      //     ]
      //   }
      // },
      "S1": {
        "tech_page_title": {"zh": ["AI 智慧停車場"], "en": ["AI Smart Parking"], "ja": ["AIスマート駐車場"]},
        "tech_page_desc": {"en": ["Over 30 Sites in Taiwan"], 
                           "zh": ["台灣30+場域"],
                           "ja": ["台湾の30以上のサイト"]},
        "tech_page_img": ["assets/story/S1_intro.jpg"],
        "tech_page_icon_flag": true,
        "web_content": {
          "Overview": [{
    "type": "tech_intro",
    "img": "assets/story/S1_intro.jpg",
    "content": {
        "zh": ["我們在台灣和新加坡安裝了超過30個停車場，管理超過3000個車位。此外，另有超過1500個車格裝設了先進的停車導引系統，並且有30+個車格包含Nexuni的智慧充電柱方案，提供使用者更高效和便利的停車體驗，全面提升停車場的管理效率和安全性。"],
        "en": ["We have installed over 30 parking lots in Taiwan and Singapore, managing more than 3000 parking spaces. Additionally, over 1500 parking spaces are equipped with advanced parking guidance systems, and more than 30 spaces include Nexuni's smart charging solutions, providing users with a more efficient and convenient parking experience, and greatly enhancing the management efficiency and safety of parking lots."],
        "ja": ["私たちは台湾とシンガポールに30以上の駐車場を設置し、3000以上の駐車スペースを管理しています。さらに、1500以上の駐車スペースには先進的な駐車ガイダンスシステムが導入され、30以上のスペースにはNexuniのスマート充電ソリューションが含まれており、ユーザーにより効率的で便利な駐車体験を提供し、駐車場の管理効率と安全性を大幅に向上させています。"]
    }
},
{
    "type": "tech_cus_and_ps",
    "title_cus": {"en": ["Customers"], "zh": ["客戶對象"], "ja": ["顧客"]},
    "content_cus": {
        "en": ["-"],
        "zh": ["＊ 新澤科技<br>＊ 大和リース株式会社<br>＊ 新加坡CBM Pte Ltd 台灣分部（世遍物業管理顧問股份有限公司）"],
        "ja": ["＊ 信沢テクノロジー<br>＊ 大和リース株式会社<br>＊ シンガポールCBM Pte Ltd 台湾支部（世遍物業管理顧問股份有限公司）"]
    },
    "title_ps": {"en": ["Problems Solved"], "zh": ["協助客戶解決的問題"], "ja": ["顧客の問題を解決"]},
    "content_ps": {
        "en": ["Modern parking equipment provides customers with multiple conveniences and security guarantees. First, the automatic payment system allows customers to quickly pay for parking, supporting various payment methods and greatly reducing queue times. Second, the parking guidance system uses sensors and displays to show available parking spaces in real-time, helping customers quickly find a spot, reducing search time, and decreasing traffic congestion within the parking lot. The license plate recognition system automatically identifies vehicle license plates, enabling automatic entry and exit management, improving security and management efficiency. Additionally, surveillance camera systems monitor the parking lot around the clock, recording vehicle movements and enhancing parking lot security. The electronic parking ticket system offers electronic tickets, replacing traditional paper tickets, which is both environmentally friendly and easier to manage. Charging stations provide charging services for electric vehicles, attracting more EV owners and enhancing the added value of the parking lot. Finally, the smart parking management system integrates various devices, providing real-time data and reports to help parking lot managers optimize operations, improving management efficiency and service quality. These applications not only increase customer satisfaction but also help parking lots achieve intelligent management and enhance competitiveness."],
        "zh": ["現代停車場設備為客戶提供了多方面的便利和安全保障。首先，自動繳費系統讓客戶可以快速支付停車費，支持多種支付方式，極大地減少了排隊時間。其次，車位引導系統通過感應器和顯示屏實時顯示可用車位，幫助客戶迅速找到空位，減少尋找車位的時間，並降低停車場內的交通擁堵。車牌識別系統則能自動識別車輛車牌號碼，實現自動進出場管理，提高安全性和管理效率。此外，監控攝像系統全天候監控停車場，記錄車輛進出和停放情況，提升停車場的安全性。電子停車票系統提供電子停車票，代替傳統紙質票據，既環保又便於管理。而充電樁為電動車提供充電服務，吸引更多電動車車主，提升停車場的附加價值。最後，智慧停車管理系統集成了各種設備，提供實時數據和報告，幫助停車場管理者優化運營，提升管理效率和服務質量。這些設備的應用，不僅提高了客戶的滿意度，也幫助停車場實現智能化管理，增強競爭力。"],
        "ja": ["現代の駐車設備は、顧客に多くの利便性と安全性を提供します。まず、自動支払いシステムは顧客が迅速に駐車料金を支払うことができ、さまざまな支払い方法に対応し、待ち時間を大幅に削減します。次に、駐車ガイダンスシステムはセンサーとディスプレイを使用して、リアルタイムで利用可能な駐車スペースを表示し、顧客が迅速にスペースを見つけるのを助け、検索時間を短縮し、駐車場内の交通渋滞を減らします。ナンバープレート認識システムは車両のナンバープレートを自動的に識別し、自動出入り管理を実現し、安全性と管理効率を向上させます。さらに、監視カメラシステムは駐車場を24時間監視し、車両の動きを記録して駐車場の安全性を向上させます。電子駐車券システムは電子チケットを提供し、従来の紙のチケットに代わり、環境に優しく管理が容易です。充電ステーションは電気自動車に充電サービスを提供し、より多くのEVオーナーを引き付け、駐車場の付加価値を高めます。最後に、スマート駐車管理システムはさまざまなデバイスを統合し、リアルタイムのデータとレポートを提供して、駐車場の管理者が運用を最適化し、管理効率とサービス品質を向上させるのに役立ちます。これらのアプリケーションは、顧客の満足度を高めるだけでなく、駐車場がインテリジェントな管理を実現し、競争力を強化するのに役立ちます。"]
    }
},

          {
    "type": "pic_word_section",
    "style": {"text": {}, "img": {}},
    "title": {
        "ja": "駐車場完全アウトドアナンバープレート識別システム",
        "en": "Complete Outdoor License Plate Recognition System for Parking Lots",
        "zh": "停車場完整戶外車牌辨識系統"
    },
    "content": {
        "ja": "Nexuniは、台北木柵、中和、桃園の和義一、和義二、忠勇、新竹の関西一、関西二、高鉄停六、芎林停一、湖口中興、湖口安宅、湖口成功、湖口王爺壟、樹杞林、新正、頭份の停二、停三、停四、停十五、文林路、中興、民生社区、活動中心、銀河路、台中の樹德、健康、德義、城隍、復興、大肚、停一、停四、停八、長義、平安といった複数の駐車場に、合計3000以上の駐車枠を管理する効率的な車両ナンバープレート認識システムを導入しました。このシステムは、科学技術装置を通じて、より多くのデータを分析および管理に提供します。システムの構造はシンプルで、設置が容易です。深層機械学習技術を採用し、高度に正確なナンバープレート認識能力と高いフォールトトレランスを備えており、認識の遅延を防ぎます。システムは、完全な電子支払いや電子請求書機能をサポートし、リモートWeb管理システムを提供します。主要技術は強力で正確なAIナンバープレート認識技術であり、システムには駐車ガイダンスシステム、自動料金機、オンライン駐車料金支払いなどの機能を含めることができ、駐車場の管理効率とユーザー体験を全体的に向上させます。",
        "en": "Nexuni has installed an intelligent and efficient license plate recognition parking management system across various locations, including Muzha and Zhonghe in Taipei; He Yi 1, He Yi 2, and Zhong Yong in Taoyuan; Guan Xi 1, Guan Xi 2, HSR Ting 6, Xionglin Ting 1, Hukou Zhongxing, Hukou Anzhai, Hukou Chenggong, Hukou Wangyelong, Shuqilin, and Xinzheng in Hsinchu; Ting 2, Ting 3, Ting 4, Ting 15, Wenlin Road, Zhongxing, Minsheng Community, Activity Center, and Yinhe Road in Toufen; and Shude, Jiankang, Deyi, Chenghuang, Fuxing, Dadou, Ting 1, Ting 4, Ting 8, Changyi, and Pingan in Taichung, managing a total of over 3000 parking spaces. This system significantly reduces the burden of manual management and provides more data for analysis and management through technological equipment. The system is simple in structure, easy to install, and employs deep machine learning technology with highly accurate license plate recognition and high fault tolerance to avoid recognition lag. It supports complete electronic payment and invoicing functions and offers a remote web management system. The core technology is powerful and accurate AI license plate recognition, and the system can include features such as a parking guidance system, automated payment machines, and online parking fee payment, significantly enhancing parking lot management efficiency and user experience.",
        "zh": "Nexuni為台北木柵、中和，桃園和義一、和義二、忠勇，新竹關西一、關西二、高鐵停六、芎林停一、湖口中興、湖口安宅、湖口成功、湖口王爺壟、樹杞林、新正，頭份停二、停三、停四、停十五、文林路、中興、民生社區、活動中心、銀河路，台中樹德、健康、德義、城隍、復興、大肚、停一、停四、停八、長義、平安等多個停車場安裝了智慧有效率的車牌辨識停車管理系統，管理超過3000個車格。該系統不僅大幅減輕人力管理負擔，還通過科技化設備提供更多數據供分析管理使用。系統架構簡單，易於安裝，採用深度機器學習技術，具備高度準確的車牌辨識能力和高容錯能力，避免辨識卡頓現象。系統支持完整的電子支付和電子發票功能，並提供遠端網頁管理系統。核心技術為強大且準確的AI車牌辨識科技，系統可選擇包含停車引導系統、自動化收款機器、線上繳停車費等功能，全面提升停車場管理效率和用戶體驗。"
    },
    "img": ["story/S1_h1.jpg"]
},
{
    "type": "word_pic_section",
    "style": {"text": {}, "img": {}},
    "title": {
        "ja": "木柵デジタルツイン示範駐車場",
        "en": "Muzha Digital Twin Demonstration Parking Lot",
        "zh": "木柵數位孿生示範停車場"
    },
    "content": {
        "ja": "木柵デジタルツイン示範駐車場には、リアルタイム3D画像システムを導入しています。このシステムは、複数のストリーミング映像を同時に処理し、すべてのネットワークカメラの映像を受信します。画像処理、深層学習オブジェクト検出モデル、特徴抽出モデル、および追跡アルゴリズムなどを組み合わせて、現場の異常状況を自動的に報告します。これにより、中央管理者が現場状況を迅速に把握できるよう支援します。また、室内PGSを利用して、画像から既存の駐車場内の空きスペースを特定し、データに基づいて駐車位置を案内することで、ユーザーの駐車スペース探索時間を短縮します。",
        "en": "The Muzha Digital Twin Demonstration Parking Lot features a real-time 3D image system. This system processes multiple streaming videos simultaneously, receiving footage from all network cameras. By combining image processing, deep learning object detection models, feature extraction models, and tracking algorithms, it proactively reports any abnormal situations on-site, assisting central administrators in quickly understanding the situation. Additionally, the indoor PGS uses images to identify available spaces within the existing parking lot, guiding vehicles to parking spots based on data, reducing the time users spend searching for parking.",
        "zh": "木柵數位孿生示範停車場安裝了即時3D影像系統，該系統利用實時多串流影像處理，同時接收所有網路攝影機的影片串流，結合影像處理、深度學習物件檢測模型、特徵抽取模型及追蹤演算法等，主動發報現場是否有異常狀況，協助中央管理人員快速了解現場狀況。同時，室內PGS利用影像取得現有停車場內空閒的位置，根據資料引導停駛位置，降低使用者尋找車位的時間。"
    },
    "img": ["story/S1_h2.jpg"]
},
{
    "type": "pic_word_section",
    "style": {"text": {}, "img": {}},
    "title": {
        "ja": "智慧車位導引系統(VPGS)",
        "en": "Smart Parking Guidance System (VPGS)",
        "zh": "智慧停車導引系統(VPGS)"
    },
    "content": {
        "ja": "Nexuniが提案するスマート駐車ガイダンスシステム (VPGS)は、画像とNXAIフレームワークをマイクロプロセッサに適用したもので、コンパクトで設置が簡単です。車両ナンバープレート認識モデルを用いたエッジコンピューティングにより、駐車スペースの占有状態を正確に判断し、さらに多くの画像データを提供して駐車場の管理分析を行います。VPGSは、設置が容易で、ナンバープレート認識率が高く、リアルタイムの駐車スペース状態を正確に表示できるだけでなく、スマートな車両検索機能を追加することも可能です。VPGSのハードウェアはシンプルで、コストが低く、追加の配線を必要とせず、一本のケーブルで一列の設備を処理でき、取り付けにはネジ二本だけで済みます。具体的な導入実例として、台中大肚には123台、新加坡には1500台の駐車枠に導入されています。",
        "en": "Nexuni's Smart Parking Guidance System (VPGS) uses imaging and the NXAI framework on microprocessors, making it compact and easy to install. By using a license plate recognition model for edge computing, it accurately determines the occupancy status of parking spaces and provides additional image data for parking lot management analysis. VPGS is not only easy to install but also boasts a high license plate recognition rate, accurately displaying real-time parking space status and can be upgraded with smart car-finding features. The VPGS hardware is simple, low-cost, and requires no additional wiring; a single cable can handle a row of devices, and only two screws are needed for installation. Specific installation examples include 123 spaces in Dadou, Taichung, and 1500 spaces in Singapore.",
        "zh": "Nexuni提出的智慧車位導引系統 (VPGS)使用影像並將NXAI框架套用在微處理器上，體積小且安裝方便，使用車牌辨識模型進行邊緣運算，可準確的判斷車位在席狀態，且可提供更多影像資料進行停車場管理分析。智慧車位導引系統 (VPGS)不僅安裝方便，而且車牌識別率高，能準確顯示即時停車位狀態，同時還可升級加入智慧尋車功能。VPGS硬體簡單，成本低廉，無需額外的線槽，一條電線即可處理一排設備，僅需兩顆螺絲即可安裝。具體安裝實例包括台中大肚123格以及新加坡1500格的車位。"
    },
    "img": ["story/S1_h3.jpg"]
},{
    "type": "video_gallery",
    "video_link": ["https://www.youtube.com/embed/95tkMefrVKw", "https://www.youtube.com/embed/SET_vlfzTcY"]
  }]
        }
      },
      "S2": {
    "tech_page_title": {
      "zh": ["AI 智慧保全"],
      "en": ["AI Smart Security"],
      "ja": ["AIスマートセキュリティ"]
    },
    "tech_page_desc": {
      "en": ["Singapore Factory"],
      "zh": ["新加坡工廠"],
      "ja": ["シンガポール工場"]
    },
    "tech_page_img": ["assets/story/S2_intro.jpg"],
    "tech_page_icon_flag": true,
    "web_content": {
      "Overview": [{
        "type": "tech_intro",
    "img": "assets/story/S2_intro.jpg",
    "content": {
      "en": ["Our AI-powered security solutions have been implemented in 13 factories in Singapore, covering all the solution contents listed below."],
      "zh": ["我們的AI智慧保全解決方案已經在新加坡13個工廠實施，並包含以下所有方案內容。"],
      "ja": ["私たちのAI搭載のセキュリティソリューションは、シンガポールの13の工場で実施されており、以下のすべてのソリューション内容をカバーしています。"]
    }
  }, {
    "type": "tech_cus_and_ps",
    "title_cus": {"en": ["Customers"], "zh": ["客戶對象"], "ja": ["顧客"]},
    "content_cus": {
      "en": ["* CBM Pte Ltd. Singapore Facility Management Company"],
      "zh": ["＊ 新加坡CBM Pte Ltd. 設施管理公司"],
      "ja": ["＊ シンガポールCBM Pte Ltd. 設備管理会社"]
    },
    "title_ps": {"en": ["Problems Solved"], "zh": ["協助客戶解決的問題"], "ja": ["顧客の問題を解決"]},
    "content_ps": {
        "en": ["Our AI-powered security system helps clients improve security efficiency, reduce labor costs, and enhance detection accuracy, effectively preventing security incidents. The system includes facial recognition access control, vehicle entry and exit management, virtual patrol, electronic fencing (AI monitoring of illegal activities), unloading area detection, fire alarm detection, and flood sensing, fully meeting the security needs of clients."],
        "zh": ["我們的AI智慧保全系統幫助客戶提高保全效率，減少人力成本，同時提升偵測準確度，有效預防安全事故發生。系統涵蓋人臉辨識門禁、車輛進出控管、虛擬巡邏、電子圍籬(AI監測非法行為)、卸貨區檢測、火災燈號偵測及洪水感測等功能，全面保障客戶的安全需求。"],
        "ja": ["私たちのAI搭載のセキュリティシステムは、顧客のセキュリティ効率を向上させ、人件費を削減し、検出精度を高め、セキュリティインシデントを効果的に防止します。このシステムには、顔認識アクセス制御、車両の出入り管理、仮想パトロール、電子フェンス（不正行為のAI監視）、荷降ろしエリアの検出、火災警報検出、および洪水センサーなどの機能が含まれており、顧客の安全ニーズを完全に満たします。"]
    }
},
      {
        "type": "pic_word_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "AIカメラ監視システム",
          "en": "AI Camera Surveillance System",
          "zh": "AI相機監控系統"
        },
        "content": {
          "ja": "AIカメラ監視システムは、リアルタイムで工場のセキュリティを監視し、異常な動きを検出した際に即座に警報を発します。このシステムは深層学習アルゴリズムを使用して、顔認識、ナンバープレート認識などの機能を提供し、工場のセキュリティを強化します。",
          "en": "The AI Camera Surveillance System monitors the factory's security in real-time and immediately alerts when abnormal movements are detected. This system uses deep learning algorithms to provide features such as facial recognition, license plate recognition, and enhances overall security.",
          "zh": "AI相機監控系統實時監控工廠的安全，當檢測到異常動作時會立即發出警報。該系統使用深度學習算法，提供人臉識別、車牌識別等功能，加強了工廠的整體安全。"
        },
        "img": ["story/S2_h1.jpg"]
      }, {
        "type": "word_pic_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "人顔認識アクセス制御システム",
          "en": "Facial Recognition Access Control System",
          "zh": "人臉識別門禁及訪客管理系統"
        },
        "content": {
          "ja": "このシステムは、one-shot learning技術を使用しており、1枚の登録写真だけで正確な顔認識が可能です。リモート訪問者登録とシステムにリンクされたインターホンを使用することで、セキュリティスタッフやユーザーは入り口に移動することなく記録と写真撮影を完了できます。",
          "en": "This system uses one-shot learning technology, enabling accurate facial recognition with just one registered photo. Remote visitor registration and intercom linked to the system allow security personnel or users to complete records and photos without moving to the entrance.",
          "zh": "該系統使用one-shot learning技術，只需一張註冊照片即可進行準確的人臉識別。遠程訪客登記和連接到該系統的對講機使保安人員或用戶無需移動到入口即可完成記錄和拍照。"
        },
        "img": ["story/S2_h2.jpg"]
      }, {
        "type": "pic_word_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "車両進出管理システム",
          "en": "Vehicle Entry and Exit Management System",
          "zh": "車輛進出管理系統"
        },
        "content": {
          "ja": "車両ナンバープレート認識システムにより、車両の進出状況を記録します。リモート訪問者登録を通じて、訪問者の車両ナンバープレートおよび関連情報を遠隔で登録できます。",
          "en": "The vehicle license plate recognition system records vehicle entry and exit statuses. Remote visitor registration allows the entry of visitor license plates and related information via intercom.",
          "zh": "車牌識別系統記錄車輛的進出狀況。通過遠程訪客登記，通過對講機遠程登記訪客車牌及相關資訊。"
        },
        "img": ["story/S2_h3.jpg"]
      }, {
        "type": "word_pic_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "AI仮想パトロールシステム",
          "en": "AI Virtual Patrol System",
          "zh": "AI虛擬巡邏系統"
        },
        "content": {
          "ja": "場内各所に設置されたネットワークカメラを通じて、セキュリティスタッフがオフィス内でスケジュールに従ってカメラを使用して巡回します。異常が検出された場合、対講システムを通じて現場で警告メッセージを再生したり、現場と音声通話を行ったりして、システムに自動的にイベントを記録します。",
          "en": "Through network cameras installed throughout the premises, security staff can patrol using cameras from the office according to a schedule. When abnormalities are detected, the system can play warning messages or communicate with the site through the intercom system, and automatically record events in the system.",
          "zh": "通過在場內各處安裝的攝影機，保安人員可以按照排程在辦公室內使用攝像機巡邏。當檢測到異常情況時，系統可以通過對講系統播放警告消息或與現場進行語音通話，並自動將事件記錄在系統中。"
        },
        "img": ["story/S2_h4.jpg"]
      }, {
        "type": "video_gallery",
        "video_link": ["https://www.youtube.com/embed/ZuQ4FkcbARU", "https://www.youtube.com/embed/Xcdz12T2U9M", "https://www.youtube.com/embed/bT96B7pg9zQ", "https://www.youtube.com/embed/aUwRUEHE70c", "https://www.youtube.com/embed/xbBPnJM1Z08"]
      }]
    }
  },
      "S3": {
    "tech_page_title": {
      "zh": ["智慧路邊停車收費系統"],
      "en": ["Smart Roadside Parking Payment System"],
      "ja": ["スマート路上駐車料金システム"]
    },
    "tech_page_desc": {
      "en": ["Toufen City"],
      "zh": ["頭份市"],
      "ja": ["頭份市"]
    },
    "tech_page_img": ["assets/story/S3_intro.jpg"],
    "tech_page_icon_flag": true,
    "web_content": {
      "Overview": [{
        "type": "tech_intro",
        "img": "assets/story/S3_intro.jpg",
        "content": {
    "en": ["Our smart roadside parking payment system has been implemented in Toufen City, managing over 1,000 roadside parking spaces and providing convenient and efficient parking management."],
    "zh": ["我們的智慧路邊停車收費系統已在頭份市實施，管理超過1000個路邊停車格，提供便利且高效的停車管理。"],
    "ja": ["私たちのスマート路上駐車料金システムは頭份市で実施されており、1,000以上の路上駐車スペースを管理し、便利で効率的な駐車管理を提供しています。"]
    }
      }, {
        "type": "tech_cus_and_ps",
        "title_cus": {
          "en": ["Customers"],
          "zh": ["客戶對象"],
          "ja": ["顧客"]
        },
        "content_cus": {
          "en": ["＊Zeta-tech (Taiwan Parking Management Company)"],
          "zh": ["＊新澤科技 (台灣停車場管理公司)"],
          "ja": ["＊新澤科技 (台湾駐車場管理会社)"]
        },
        "title_ps": {
          "en": ["Problems Solved"],
          "zh": ["協助客戶解決的問題"],
          "ja": ["顧客の問題を解決"]
        },
        "content_ps": {
          "en": ["Nexuni's smart roadside parking payment system introduces a diversified payment system supporting up to 19 payment methods, enhancing payment convenience and reducing fare evasion. Additionally, the smart management system incorporates data management, allowing real-time monitoring of roadside parking operations. The ticketing data is integrated into a central platform, enabling the public to check their parking fee status online, reducing customer service calls and maintenance costs."],
          "zh": ["Nexuni智慧路邊停車收費系統引入多元化繳費系統，支援多達19種支付方式，提升支付便捷性，減少逃費狀況。此外智慧化管理系統，引進數據管理，即時了解路邊停車營運狀況。開單資料也整合到大平台上，民眾可透過網頁查詢到停車費狀態，減少致電客服頻率，降低維護成本。"],
          "ja": ["Nexuniのスマート路上駐車料金システムは、19種類の支払い方法をサポートする多様化された支払いシステムを導入し、支払いの利便性を向上させ、料金の逃避を減らします。さらに、スマート管理システムはデータ管理を導入し、路上駐車の運営状況をリアルタイムで把握できます。チケットデータは中央プラットフォームに統合されており、一般の人々はオンラインで駐車料金の状況を確認できるため、カスタマーサービスへの電話の頻度が減り、メンテナンスコストが削減されます。"]
        }
      }, {
        "type": "pic_word_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
    "ja": "スマート路上駐車料金システム",
    "en": "Smart Roadside Parking Payment System",
    "zh": "智慧路邊停車收費系統"
  },
        "content": {
          "ja": "Zeta techのスマート路上駐車システムは、19種類以上の支払い方法をサポートしており、台湾の主要な4つのコンビニエンスストアでのバーコード支払い、多媒体機でのオンライン確認と支払い、5つの多様な支払いウェブサイトとアプリでのオンライン支払い、1つの金融機関での控除を提供しています。さらに、ユーザーは未払いの車両と料金、過去の支払い履歴、関連通知を確認することができます。",
          "en": "The Zeta tech smart roadside parking system supports over 19 payment methods, including barcode payments at Taiwan's four major convenience stores, online inquiries and payments through multimedia machines, online payments via five diverse payment websites and apps, and deductions through one financial institution. Additionally, users can check unpaid vehicle fees, payment history, and related announcements.",
          "zh": "Zeta tech智慧路邊停車系統支持超過19種支付方式，包括台灣四大超商的條碼支付、多媒體機上的在線查詢和支付、5家多元支付網站和APP的在線支付，以及1家金融機構的扣款。用戶還可以查詢未繳費車輛和費用、歷史繳費記錄及相關公告。"
        },
        "img": ["story/S3_h1.jpg"]
      }, {
        "type": "word_pic_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "民衆前端查詢系統",
          "en": "Public Front-end Website",
          "zh": "民眾前端查詢系統"
        },
        "content": {
          "ja": "ユーザーは、未払いの車両と料金、過去の支払い履歴、関連通知を確認できます。このシステムは、ユーザーが自分の支払い状況を簡単に確認できるようにし、カスタマーサービスへの電話の頻度を減らし、管理コストを削減します。",
          "en": "Users can check unpaid vehicle fees, payment history, and related announcements. This system allows users to easily monitor their payment status, reducing the frequency of customer service calls and lowering management costs.",
          "zh": "用戶可以查詢未繳費車輛和費用、歷史繳費記錄及相關公告。此系統使用戶能夠輕鬆監控自己的支付狀況，減少致電客服的頻率，降低管理成本。"
        },
        "img": ["story/S3_h2.jpg"]
      }, {
        "type": "pic_word_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "後台管理系統",
          "en": "Back-end Management System",
          "zh": "後台管理系統"
        },
        "content": {
          "ja": "管理者は、未払いの車両と料金、過去の支払い履歴を確認し、日次レポート、打点記録表、運営レポート、催促項目と関連リストを生成できます。このシステムは、運営効率を高め、管理を簡素化します。",
          "en": "Managers can check unpaid vehicle fees, payment history, generate daily reports, punch records, operational reports, and lists of items for follow-up. This system enhances operational efficiency and simplifies management.",
          "zh": "管理者可以查詢未繳費車輛和費用、歷史繳費記錄，生成日報表、打點記錄表、營運報表及催繳項目和相關名單。此系統提升了運營效率，簡化了管理工作。"
        },
        "img": ["story/S3_h3.jpg"]
      }, {
        "type": "word_pic_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "PDA手持開單系統",
          "en": "PDA Handheld Ticketing System",
          "zh": "PDA手持開單系統"
        },
        "content": {
          "ja": "PDA手持開単システムは、駐車券を発行し、キャンセルし、QRコード付きの支払い券を印刷することができます。大きなキーボードとセル番号選択などの人間工学に基づいたインターフェースを備えています。",
          "en": "The PDA handheld ticketing system can issue and cancel parking tickets and print payment tickets with QR codes. It features an ergonomic interface with a large keyboard and cell number selection.",
          "zh": "PDA手持開單系統可以開立和註銷停車單，打印帶有QR碼的繳費單。該系統具有大鍵盤和格號選取等人性化界面。"
        },
        "img": ["story/S3_h4.jpg"]
      }, {
        "type": "video_gallery",
        "video_link": ["https://www.youtube.com/embed/EuaiJqOeA5Y"]
      }]
    }
  },
      "S4": {
    "tech_page_title": {
      "zh": ["智慧廁所管理"],
      "en": ["Smart Toilet Management"],
      "ja": ["スマートトイレ管理"]
    },
    "tech_page_desc": {
      "en": ["Singapore"],
      "zh": ["新加坡"],
      "ja": ["シンガポール"]
    },
    "tech_page_img": ["assets/story/S4_intro.jpg"],
    "tech_page_icon_flag": true,
    "web_content": {
      "Overview": [{
        "type": "tech_intro",
        "img": "assets/story/S4_intro.jpg",
        "content": {
    "en": ["Our smart toilet management system has been installed in over 150 toilets across Singapore, providing customers with intelligent and efficient restroom management."],
    "zh": ["我們的智慧廁所管理系統已安裝在新加坡超過150個廁所，提供客戶智慧且高效的廁所管理。"],
    "ja": ["私たちのスマートトイレ管理システムはシンガポールの150以上のトイレに設置されており、顧客にスマートで効率的なトイレ管理を提供しています。"]
  }
      }, {
        "type": "tech_cus_and_ps",
        "title_cus": {
          "en": ["Customers"],
          "zh": ["客戶對象"],
          "ja": ["顧客"]
        },
        "content_cus": {
          "en": ["* CBM Pte Ltd. Singapore Facility Management Company"],
          "zh": ["＊ 新加坡CBM Pte Ltd. 設施管理公司"],
          "ja": ["＊ シンガポールCBM Pte Ltd. 設備管理会社"]
        },
        "title_ps": {
          "en": ["Problems Solved"],
          "zh": ["協助客戶解決的問題"],
          "ja": ["顧客の問題を解決"]
        },
        "content_ps": {
          "en": ["Our system equips each restroom with advanced sensors and management devices, including ammonia sensors, toilet paper sensors, hand soap sensors, feedback panels, and image-based traffic detection, providing a comprehensive restroom management solution. Through the integration of AI and IoT technologies, the system collects and analyzes various data in real-time, automating management tasks to enhance restroom efficiency and user satisfaction."],
          "zh": ["本系統於每個廁所場域內皆配備了先進的感測器和管理設備，包括氨氣感測器、廁所紙感測器、洗手液感測器、回饋面板及影像人流檢測，提供全面的廁所管理解決方案。透過AI和IoT技術的整合，系統能實時收集和分析各種數據，並自動化處理各項管理任務，提升廁所的使用效率和使用者滿意度。"],
          "ja": ["このシステムは、各トイレに高度なセンサーと管理デバイスを備えています。これには、アンモニアセンサー、トイレットペーパーセンサー、ハンドソープセンサー、フィードバックパネル、画像ベースのトラフィック検出が含まれており、包括的なトイレ管理ソリューションを提供します。AIとIoT技術の統合を通じて、システムはさまざまなデータをリアルタイムで収集および分析し、管理タスクを自動化してトイレの効率とユーザー満足度を向上させます。"]
        }
      }, {
        "type": "pic_word_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "智慧廁所管理系統",
          "en": "Smart Toilet Management System",
          "zh": "智慧廁所管理系統"
        },
        "content": {
          "ja": "CBMのスマートトイレ管理システムには、管理者インターフェースが装備されており、すべてのセンサーからのデータを受信し、リアルタイムでステータスを表示し、レポートを生成します。管理者インターフェースは、トイレの使用状況と機器の稼働状況をリアルタイムで把握するための明確なデータビジュアライゼーションを提供し、効果的な管理と意思決定をサポートします。",
          "en": "The CBM smart toilet management system is equipped with a management interface that receives data from all sensors, displays status in real-time, and generates reports. The management interface provides clear data visualization, helping administrators understand the usage and operation status of restrooms in real-time, facilitating effective management and decision-making.",
          "zh": "CBM智慧廁所管理系統配備了管理者介面，可接收各感測器回傳的資料，實時顯示狀態，並統計整理產生報表。管理者介面提供清晰的數據視覺化，幫助管理人員即時了解廁所的使用狀況和設備運行狀況，進行有效的管理和決策。"
        },
        "img": ["story/S4_h1.jpg"]
      }, {
        "type": "word_pic_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "センサー",
          "en": "Sensors",
          "zh": "感測器"
        },
        "content": {
          "ja": "アンモニアセンサーは、トイレ内のアンモニア濃度をリアルタイムで監視し、空気の質を確保します。異常濃度が検出された場合、システムは即座に警告を発し、清掃スタッフに通知して対処します。トイレットペーパーセンサーは、各トイレの隔間に設置され、トイレットペーパーの使用量をリアルタイムで監視します。トイレットペーパーが不足しそうになると、システムは自動的に警告を発し、補充を通知します。ハンドソープセンサーは、ハンドソープの使用状況を監視します。ハンドソープが不足しそうになると、システムは即座に補充を通知し、十分な供給を確保してユーザーの洗手体験を向上させます。",
          "en": "The ammonia sensor monitors the ammonia concentration in the restroom in real-time to ensure air quality. If abnormal concentrations are detected, the system immediately alerts and notifies cleaning staff to handle the situation. The toilet paper sensor is installed in each restroom stall and monitors toilet paper usage in real-time. When the toilet paper is about to run out, the system automatically alerts and notifies for replenishment. The hand soap sensor monitors the usage of hand soap. When the hand soap is about to run out, the system immediately notifies for replenishment, ensuring adequate supply and enhancing the user’s handwashing experience.",
          "zh": "氨氣感測器即時監測廁所內的氨氣濃度，確保空氣質量。一旦檢測到異常濃度，系統會立即發出警示通知清潔人員進行處理，保持環境衛生。廁所紙感測器安裝於每個廁所隔間內，實時監控廁所紙的使用量，當廁所紙即將耗盡時，系統會自動警示以通知補給，確保廁所紙的供應不間斷。洗手液感測器監控洗手液的使用狀況，當洗手液即將用完時，系統會即時通知補給，確保洗手液的充足供應，提升使用者的洗手體驗。"
        },
        "img": ["story/S4_h2.jpg"]
      }, {
        "type": "pic_word_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "フィードバックパネル",
          "en": "Feedback Panel",
          "zh": "回饋面板"
        },
        "content": {
          "ja": "フィードバックパネルはトイレの出口に設置されており、ユーザーはトイレの清潔さや設備の状態についてリアルタイムでフィードバックを行うことができます。フィードバックデータは記録および分析され、管理側がサービス品質を向上させるのに役立ちます。",
          "en": "The feedback panel is installed at the restroom exit, allowing users to provide real-time feedback on the cleanliness and condition of the facilities. Feedback data is recorded and analyzed, helping management improve service quality.",
          "zh": "回饋面板設置於廁所出口處，使用者可在使用後即時反饋廁所的清潔度和設施狀況。回饋數據將被記錄並分析，幫助管理方提升服務質量。"
        },
        "img": ["story/S4_h3.jpg"]
      }, {
        "type": "word_pic_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "画像人流検出",
          "en": "Image-based Traffic Detection",
          "zh": "影像人流檢測"
        },
        "content": {
          "ja": "トイレの入口に設置されたカメラを使用して人流を検出し、トイレの使用状況を統計します。システムは人流データに基づいて清掃頻度を自動的に調整し、管理効率を向上させ、ピーク時の衛生問題を回避します。",
          "en": "Using cameras installed at restroom entrances, the system detects and counts restroom traffic. Based on traffic data, the system automatically adjusts cleaning frequency, improving management efficiency and avoiding hygiene issues during peak times.",
          "zh": "利用門口的攝影機進行人流檢測，統計廁所使用情況。系統可根據人流數據自動調整清潔頻率，提升管理效率，避免高峰時段的衛生問題。"
        },
        "img": ["story/S4_h4.jpg"]
      }, {
        "type": "video_gallery",
        "video_link": ["https://www.youtube.com/embed/Nno6nnffuMw"]
      }]
    }
  },
      "S5": {
    "tech_page_title": {
      "zh": ["智慧店面自動化"],
      "en": ["Smart Store Automation"],
      "ja": ["スマートストア自動化"]
    },
    "tech_page_desc": {
      "en": ["DoDay"],
      "zh": ["DoDay豆日子"],
      "ja": ["DoDay豆日子"]
    },
    "tech_page_img": ["assets/story/S5_intro.jpg"],
    "tech_page_icon_flag": true,
    "web_content": {
      "Overview": [{
        "type": "tech_intro",
        "img": "assets/story/S5_intro.jpg",
        "content": {
          "en": ["Our smart store automation solutions are implemented at DoDay, providing efficient and intelligent management for the dessert chain."],
          "zh": ["我們的智慧店面自動化解決方案已在DoDay豆日子實施，為甜品連鎖品牌提供高效且智能的管理。"],
          "ja": ["私たちのスマートストア自動化ソリューションはDoDay豆日子で実施されており、デザートチェーンに効率的でインテリジェントな管理を提供しています。"]
        }
      }, {
        "type": "tech_cus_and_ps",
        "title_cus": {
          "en": ["Customers"],
          "zh": ["客戶對象"],
          "ja": ["顧客"]
        },
        "content_cus": {
          "en": ["＊DoDay （Taiwan Dessert Chain Brand）"],
          "zh": ["＊DoDay （台灣甜品連鎖品牌）"],
          "ja": ["＊DoDay （台湾デザートチェーンブランド）"]
        },
        "title_ps": {
          "en": ["Problems Solved"],
          "zh": ["協助客戶解決的問題"],
          "ja": ["顧客の問題を解決"]
        },
        "content_ps": {
          "en": ["Nexuni's smart store automation utilizes AI technology to provide an efficient and intelligent solution for the food and beverage industry. The system includes ordering kiosks and an online ordering platform, allowing customers to place orders quickly through the system. The back-end uses big data analysis to accurately recommend dishes and optimize menu arrangement. This system significantly reduces labor costs, improves order processing efficiency, enhances customer service experience, and thus increases restaurant operational efficiency and competitiveness."],
          "zh": ["Nexuni的智慧店面自動化利用人工智慧技術，為餐飲業提供一套高效、智能的解決方案。系統包括點餐機和線上點餐平台，讓顧客可以通過系統快速下單。後端使用大數據分析，精準推薦菜品並優化菜單排列。該系統能顯著降低人工成本，提升訂單處理效率，增強顧客服務體驗，從而提高餐廳的運營效益和競爭力。"],
          "ja": ["Nexuniのスマートストア自動化はAI技術を利用して、飲食業界に効率的でインテリジェントなソリューションを提供します。システムには注文キオスクとオンライン注文プラットフォームが含まれており、顧客はシステムを通じて迅速に注文を行うことができます。バックエンドではビッグデータ分析を使用して、正確に料理を推薦し、メニューの配置を最適化します。このシステムは、労働コストを大幅に削減し、注文処理効率を向上させ、顧客サービス体験を強化し、レストランの運営効率と競争力を高めます。"]
        }
      }, {
        "type": "pic_word_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "DoDay 智慧店面自動化",
          "en": "DoDay Smart Store Automation",
          "zh": "DoDay 智慧店面自動化"
        },
        "content": {
          "ja": "DoDayのスマートストア自動化には、スマート支払い機システムが含まれており、人件費とレジ時間を節約し、キャッシュフローの流れと販売統計を管理します。このシステムは多様な支払い方法をサポートしており、現金を持っていない顧客の購入意欲を高め、長蛇の列を避けることができます。また、バックエンド在庫システムと接続されており、クリエイティブなアイコンのスライドショーや精確なメニューの推薦を提供します。POSシステムは注文ミスを減らし、複数の配達プラットフォーム機能と連携し、重複注文を避けます。",
          "en": "The DoDay smart store automation includes a smart payment machine system that saves labor costs and cashier time, and manages cash flow and sales statistics. This system supports multiple payment methods, increasing customers' willingness to purchase and avoiding long queues. It is also connected to the back-end inventory system, providing creative icon slideshows and precise menu recommendations. The POS system reduces order errors, integrates multiple delivery platform functions to avoid duplicate orders.",
          "zh": "DoDay智慧店面自動化包括智慧繳費機系統，節省人力成本和收銀時間，管控金流流向和銷售統計。此系統支持多種支付方式，增加顧客購買意願，避免排長隊。系統還連接後端庫存系統，提供創意圖示輪播和精準推薦菜單。POS系統降低點餐錯誤率，串接多外送平台功能避免重複打單。"
        },
        "img": ["story/S5_h1.jpg"]
      }, {
        "type": "word_pic_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "POS系統",
          "en": "POS System",
          "zh": "POS系統"
        },
        "content": {
          "ja": "POSシステムは、注文ミスを減らし、複数の配達プラットフォーム機能と連携して重複注文を避け、在庫関連情報を提供します。電子請求書の発行機能もあり、打刻システムも提供しています。",
          "en": "The POS system reduces order errors, integrates multiple delivery platform functions to avoid duplicate orders, and provides inventory-related information. It also has an electronic invoice issuance function and provides a clock-in system.",
          "zh": "POS系統降低點餐錯誤率，串接多外送平台功能避免重複打單，提供庫存相關資料。還有開立電子發票功能和打卡系統。"
        },
        "img": ["story/S5_h2.jpg"]
      }, {
        "type": "pic_word_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "餐飲後台管理系統",
          "en": "Restaurant Back-end Management System",
          "zh": "餐飲後台管理系統"
        },
        "content": {
          "ja": "このシステムは、在庫管理、注文処理、従業員管理、給与管理を提供し、運営報告書や財務報告書を生成します。オンライン注文システムは、ウェブサイトでの注文履歴と予約注文、オンライン支払いシステムも提供しています。",
          "en": "This system provides inventory management, order processing, employee management, and payroll management, generating operational and financial reports. The online ordering system offers web-based order history and reservation orders, along with an online payment system.",
          "zh": "此系統提供庫存管理、訂單處理、員工管理和薪資管理，生成運營報表和財務報表。線上點餐系統提供網站訂單歷史和預約訂購，以及線上支付系統。"
        },
        "img": ["story/S5_h3.jpg"]
      },  {
        "type": "video_gallery",
        "video_link": ["https://www.youtube.com/embed/6Shcu-qJTWM"]
      }]
    }
  },
      "S6": {
    "tech_page_title": {
      "zh": ["AI協作式機器人應用"],
      "en": ["AI Collaborative Robot Application"],
      "ja": ["AI協働ロボットアプリケーション"]
    },
    "tech_page_desc": {
      "en": ["Factory Automation"],
      "zh": ["工廠自動化"],
      "ja": ["工場自動化"]
    },
    "tech_page_img": ["assets/story/S6_intro.jpg"],
    "tech_page_icon_flag": true,
    "web_content": {
      "Overview": [{
        "type": "tech_intro",
        "img": "assets/story/S6_intro.jpg",
        "content": {
    "en": ["Our AI collaborative robot solutions have been installed in a biotech company factory listed in Taiwan, enhancing factory automation and making biotech production more efficient and reliable."],
    "zh": ["我們的AI協作式機器人解決方案安裝在一家在台灣上市的生技公司的工廠，提高了工廠自動化，使生物科技生產更加高效和可靠。"],
    "ja": ["私たちのAI協働ロボットソリューションは、台湾に上場しているバイオテクノロジー企業の工場に設置されており、工場の自動化を向上させ、バイオテクノロジー生産をより効率的で信頼性の高いものにしています。"]
  }
      }, {
        "type": "tech_cus_and_ps",
        "title_cus": {
          "en": ["Customers"],
          "zh": ["客戶對象"],
          "ja": ["顧客"]
        },
        "content_cus": {
          "en": ["＊A Biotech Company listed in Taiwan"],
          "zh": ["＊在台灣上市的生技公司"],
          "ja": ["＊台湾上場のバイオテクノロジー企業"]
        },
        "title_ps": {
          "en": ["Problems Solved"],
          "zh": ["協助客戶解決的問題"],
          "ja": ["顧客の問題を解決"]
        },
        "content_ps": {
          "en": ["By combining UR arms with depth cameras, biotech production becomes easier and more efficient. The depth camera accurately detects anomalies in the items being handled and transmits this data to the control system. This system can handle anomalies that are typically difficult to manage, enhancing work efficiency in the medical industry, reducing staff workload, and ensuring product quality."],
          "zh": ["透過UR手臂結合深度攝影機，生物科技生產變得更加輕鬆和高效。深度攝影機能準確感知過程中夾取的物品是不是有異常，透過將這些數據傳遞給控制系統。完成一般做不到的異常處理，這樣的系統不僅可以提高醫療行業的工作效率，還能夠減輕人員的工作負擔，確保產品的品質。"],
          "ja": ["URアームと深度カメラを組み合わせることで、バイオテクノロジーの生産がより容易かつ効率的になります。深度カメラは、処理されている物品の異常を正確に検知し、このデータを制御システムに送信します。このシステムは通常は難しい異常処理を実現し、医療業界の作業効率を向上させ、スタッフの負担を軽減し、製品の品質を確保します。"]
        }
      }, {
        "type": "pic_word_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "協作機械手臂",
          "en": "Collaborative Robot Arm",
          "zh": "協作機械手臂"
        },
        "content": {
          "ja": "協働ロボットアームは、人間と安全に協力するように設計されたロボットアームです。従来の産業用ロボットアームとは異なり、高い感知能力と安全特性を備えており、安全柵なしで人間と一緒に作業できます。",
          "en": "The collaborative robot arm is designed to work safely alongside humans. Unlike traditional industrial robot arms, it features high sensory capabilities and safety characteristics, allowing it to operate without safety barriers.",
          "zh": "協作機械手臂，是一種設計用來與人類安全合作的機械手臂。與傳統的工業機械手臂不同，具備高感知能力和安全特性，允許它們在沒有安全柵欄的情況下與人類共同工作。"
        },
        "img": ["story/S6_h1.jpg"]
      }, {
        "type": "word_pic_section",
        "style": {
          "text": {},
          "img": {}
        },
        "title": {
          "ja": "手臂路徑反饋及規劃",
          "en": "Arm Path Feedback and Planning",
          "zh": "手臂路徑反饋及規劃"
        },
        "content": {
          "ja": "手臂路徑反饋及規劃技術涉及使用感應器和控制算法來監測和調整機械手臂的運動軌跡，避免障礙並達到目標位置。",
          "en": "Arm path feedback and planning technology involves using sensors and control algorithms to monitor and adjust the movement trajectory of the robot arm, avoiding obstacles and achieving the target position.",
          "zh": "手臂路徑反饋及規劃技術涉及使用感應器和控制算法來監測和調整機械手臂的運動軌跡，避免障礙並達到目標位置。"
        },
        "img": ["story/S6_h2.jpg"]
      }],
    }
  }

    }

    this.setState({
      tech_page_flag: true,
      tech_page_icon_flag: tech_content[page_title]["tech_page_icon_flag"],
      tech_page_title: tech_content[page_title]["tech_page_title"],
      tech_page_desc: tech_content[page_title]["tech_page_desc"],
      tech_page_img: tech_content[page_title]["tech_page_img"],
      web_content: tech_content[page_title]["web_content"]
    })
  }

}

export default TechPage;
