import React, { Component } from 'react'

class PicWordWithBtn extends Component {

    constructor(props) {
        super(props)
    }

    emptyFunc() {}
    
    render() {
        return (
            <div className="pic_word_with_btn" onClick={this.props.action !== "" ? this.props.action : this.emptyFunc}>
                <div className="img_holder">
                    <img src={`${process.env.PUBLIC_URL}/image/` + this.props.img}/>
                </div>
                <div className="info_holder">
                    <h2>{this.props.title}</h2>
                    <h5>{this.props.content}</h5>
                    {this.props.action !== "" ? <button>{this.props.lang === "en" ? "Learn more>" : "了解更多>"}</button> : <></>}
                </div>
            </div>
        );
    }
}

export default PicWordWithBtn;