import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

import { Slide } from 'react-slideshow-image';
import SlideShow from './SlideShow'

import '../css/OpenSlideShow.css';

class OpenSlideShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_lang: this.props.lang,
      startAnimate: false,
      slideImages: ["/assets/mainpage_home1-1.webp", "/assets/mainpage_home1-2.webp", "/assets/mainpage_home1-3.webp"],
      slideTitle: {
        "zh": ["Always In Advance.", "Collaborate with Robots.", "Innovation in AI."], 
        "en": ["Always In Advance.", "Collaborate with Robots.", "Innovation in AI."], 
        "ja": ["Always In Advance.", "Collaborate with Robots.", "Innovation in AI."]},
      slideContent: {
        "zh": ["我們善於結合AI人工智慧與機器人等先進技術，為您的企業量身打造端對端解決方案。我們的目標是提供一套既能減少傳統人力需求、又能提升工作效率的方案，能夠快速且無縫地整合進您公司的營運流程中", "我們是 Universal Robots 認證的 Solution Provider以及Distributor，並且致力於利用協作式機器人開發最先進的自動化解決方案來幫助客戶解決複雜問題。若有機器手臂解決方案的開發需求或採購，請聯絡我們。", "Nexuni 致力於將 AI 整合於硬體系統，並引入日常生活中。我們希望能協助客戶提升營運效率，解決人力短缺問題。我們與 NVIDIA 密切合作，參與他們的 Inception Program，使用最先進的硬體設備和軟體框架來開發 AI 自動化解決方案。"], 
        "en": ["We specialize in using AI and robotics to create end-to-end solutions for your business. Our goal is to reduce manual work and improve quality, ensuring our solutions are ready for your business to implement immediately. These solutions streamline operations and are designed to be efficient and cost-effective right from the start.", "We are the Certified Solution Provider and Distributor of Universal Robots, dedicated to using collaborative robots to develop cutting-edge automation solutions to help customers solve complex problems. If you have any development or procurement needs for robotic arm solutions, please contact us.", "Nexuni is committed to integrating AI with hardware systems and incorporating it into everyday life. We aim to help our customers improve operational efficiency and address labor shortages. We join the NVIDIA Inception Program and use cutting-edge hardware and software frameworks to develop AI automation solutions."], 
        "ja": ["私たちはAI人工知能とロボット技術などの先端技術を組み合わせ、企業に特化したエンドツーエンドのソリューションを提供することに長けています。私たちの目標は作業効率を向上させるソリューションを提供し、それをお客様の会社の運営に迅速に組み込めるようにします。", "私たちは、Universal Robots 認定のソリューションプロバイダーおよびディストリビューターであり、協働ロボットを活用して最先端の自動化ソリューションを開発し、お客様の複雑な課題を解決することに専念しています。ロボットアームのソリューションに関する開発や調達のニーズがある場合は、ぜひご連絡ください。", "Nexuniは、AIをハードウェアシステムと統合し、日常生活に取り入れることに専念しています。お客様の運営効率の向上と労働力不足の解消を目指しています。私たちはNVIDIAと密接に協力し、Inception Programに参加して、最先端のハードウェアとソフトウェアフレームワークを使用してAI自動化ソリューションを開発しています。"]},
      slideAction: [""]
    }
  }

  componentDidMount() {
    this.setState({
      startAnimate: true
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lang !== this.state.lang) {
      this.setState({ current_lang: nextProps.lang });
    }
  }
  
  render() {

        return (
          <>
            <div className={"opening_section "+this.state.current_lang}>

                <div className="slide_area"
                  style={{
                    animation: "fade-in 3s ease 3.5s forwards"
                  }}>
                  <SlideShow version={"v2_"} lang={this.state.current_lang} title={this.state.slideTitle[this.state.current_lang]} content={this.state.slideContent[this.state.current_lang]} action={this.state.slideAction} img={this.state.slideImages} img_num={this.state.slideImages.length} arrow_flag={true} logo_flag={false}/>
                </div>

                <div className="opening_area_x_logo">
                  <img id="opening_area_x_logo_l" src="/assets/mainpage_nexuni_opening_l.svg" alt="blk-r" 
                    style={{
                      animation: "move-right 1.5s ease 0s forwards, fade-out 3s ease 0.75s forwards"
                    }}/>
                  <img id="opening_area_x_logo_r" src="/assets/mainpage_nexuni_opening_r.svg" alt="blk-l" 
                    style={{
                      animation: "move-left 1.5s ease 0s forwards, fade-out 3s ease 0.75s forwards"
                    }}/>
                </div>

                <div className="opening_area_nexuni_logo">
                  <img id="opening_area_nexuni_logo_n1" src="/assets/mainpage_nexuni_n.svg" alt="n" 
                    style={{
                      animation: "fade-in 3s ease 1.5s forwards, fade-out 0.75s ease 3s forwards"
                    }}/>
                  <img id="opening_area_nexuni_logo_e" src="/assets/mainpage_nexuni_e.svg" alt="e" 
                    style={{
                      animation: "fade-in 3s ease 1.8s forwards, fade-out 0.75s ease 3s forwards"
                    }}/>
                  <img id="opening_area_nexuni_logo_x" src="/assets/mainpage_nexuni_x.svg" alt="x" 
                    style={{
                      animation: "fade-in 3s ease 2.1s forwards, fade-out 0.75s ease 3s forwards"
                    }}/>
                  <img id="opening_area_nexuni_logo_u" src="/assets/mainpage_nexuni_u.svg" alt="u" 
                    style={{
                      animation: "fade-in 3s ease 2.4s forwards, fade-out 0.75s ease 3s forwards"
                    }}/>
                  <img id="opening_area_nexuni_logo_n2" src="/assets/mainpage_nexuni_n.svg" alt="n" 
                    style={{
                      animation: "fade-in 3s ease 2.7s forwards, fade-out 0.75s ease 3s forwards"
                    }}/>
                  <img id="opening_area_nexuni_logo_i" src="/assets/mainpage_nexuni_i.svg" alt="i" 
                    style={{
                      animation: "fade-in 3s ease 3s forwards, fade-out 0.75s ease 3s forwards"
                    }}/>
                </div>

            </div>
          </>
        ) 
  }
}
export default OpenSlideShow;


// import React from 'react';
// import { Slide } from 'react-slideshow-image';

// const properties = {
//   duration: 5000,
//   transitionDuration: 500,
//   infinite: true,
//   indicators: true,
//   arrows: true,
//   onChange: (oldIndex, newIndex) => {
//     console.log(`slide transition from ${oldIndex} to ${newIndex}`);
//   }
// }

// const Slideshow = () => {
//     return (
//       <div className="slide-container">
//         <Slide {...properties}>
//           <div className="each-slide">
//             <div style={{'backgroundImage': `url(${slideImages[0]})`}}>
//               <span>Slide 1</span>
//             </div>
//           </div>
//           <div className="each-slide">
//             <div style={{'backgroundImage': `url(${slideImages[1]})`}}>
//               <span>Slide 2</span>
//             </div>
//           </div>
//           <div className="each-slide">
//             <div style={{'backgroundImage': `url(${slideImages[2]})`}}>
//               <span>Slide 3</span>
//             </div>
//           </div>
//         </Slide>
//       </div>
//     )
// }
