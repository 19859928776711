import React, { Component } from 'react'

class VideoGallery extends Component {
    
    
    render() {
    	var vlink = this.props.video_link ? this.props.video_link : []
        return (
        	<div className="VideoGallery">
        		{vlink.map((item) => <iframe src={item}></iframe>)}
			</div>
        );
    }
}

export default VideoGallery;