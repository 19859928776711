import React, { Component } from 'react';

import { setCookie, getCookie } from '../utils/cookie_utils.js'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import '../css/old_web.css';

class BlogPage extends Component {
  
  constructor(props) {
    var lang = "zh"
    if (getCookie("lang") !== "") {
      lang = getCookie("lang")
    }

    super(props);
    this.general_switch_page = this.general_switch_page.bind(this)
    this.update_lang = this.update_lang.bind(this)
    this.state = {
      lang: lang, // Assuming you have a language state
      content: [
        {
          "id": "latest_news_1",
          "category": "公司消息",
          "title": "Nexuni X Acer 智慧城市展",
          "author": "Nexuni小編",
          "date": "03.23.2024",
          "image": "/image/blog10.png",  // Assuming the image name
          "backgroundColor": "#a1a1a1",
          "hashtags": ["#nexuni", "#ai", "#smartcity", "#digitaltwins", "#computervision"],
          "shortContent": "[Nexuni X Acer 智慧城市展]",
          "fullContent": `Nexuni在這次的智慧城市展主要展示了四個主題，人流檢測、姿態分析互動、智慧安保系統、數位孿生。
                        在這四個主題中，我們由小到大展示了我們是如何建構智慧城市的概念。從單支攝影機下做的影片分析開始，我們可以利用AI的技術解決客製化的特殊任務。例如利用姿態分析，我們在新加坡落地實施檢測卸貨區人員是否真的是在卸貨，亦或是在家中老人獨自在家跌倒時可以緊急發報。
                        而我們的智慧安保系統整合了多項AI影片分析及IoT技術，包含人臉、車牌進出控管、虛擬巡邏、電子圍籬、火災檢測、淹水檢測、遠端控制等功能，使安保或管理人員可於遠端的戰情中心實現多場域的控管。
                        數位孿生則是中央控制系統更進化的版本，透過各種不同的感測器及攝影機蒐集所有觀測到的數據，並利用AI進行分析、整合，最後將所有的結果融合，並藉由建模與圖形介面還原一張場域的地圖上，打造實際場域的虛擬數位分身。使用者透過這個數位孿生，就彷彿人置身現場一般，各個角落的情況同時盡收眼底，並搭配AI主動分析，可以在介面上對預設的情況示警，並遠端遙控來處理現場情況。
                        這次展覽中，多數的參訪者認為我們的姿態分析的互動小遊戲很吸睛，在看到精準的骨架偵測及動作分析後，讓他們有了更多的發想。另外也非常多人對於我們實時的數位孿生很感興趣，認為數位孿生的視覺化界面及方便性，搭配上原有的管理介面，或許會是未來中控系統很好的目標。
                        <a href="https://www.instagram.com/nexuni.co/p/C422q3ULkQ9/">查看原文</a>`
        },
        {
          "id": "latest_news_2",
          "category": "公司消息",
          "title": "AI智慧停車場示範場動土開工",
          "author": "Nexuni小編",
          "date": "01.10.2024",
          "image": "/image/blog11.png",  // Assuming the image name
          "backgroundColor": "#a1a1a1",
          "hashtags": ["#nexuni", "#carday", "#ai", "#parking", "#innovation", "#technology", "#aiot"],
          "shortContent": "Nexuni 的 Carday AI智慧停車場示範場動土開工",
          "fullContent": `Nexuni 的 Carday AI智慧停車場示範場這週在台北市木柵正式動土開工，我們希望透過最新的技術來進行更有效率的停車場管理並提供當地居民最安心、最方便的停車體驗。 文山區的賴士葆委員、王議員以及林里長也到場參與動土儀式，祝一切順利也希望Carday能服務更多民眾🎉🎉
          <a href="https://www.instagram.com/nexuni.co/p/C18IsLZyXhK/">查看原文</a>`
        },
        {
          "id": "latest_news_3",
          "category": "公司消息",
          "title": "全新概念的未來世代停車場營運商品牌 - CarDay 來了！",
          "author": "Nexuni小編",
          "date": "08.14.2023",
          "image": "/image/blog12.png",  // Assuming the image name
          "backgroundColor": "#a1a1a1",
          "hashtags": ["#CarDay天天停車", "#未來世代停車場", "#更省心更安心更貼心", "#nexuni"],
          "shortContent": "全新概念的未來世代停車場營運商品牌 - CarDay 來了！",
          "fullContent": `全新概念的未來世代停車場營運商品牌 - CarDay 來了！ CarDay 採用了Nexuni的全方位智慧停車解決方案，致力於為車主提供一個更省心、更安心、更貼心的停車環境：
                        <br /><br />
                        1 ）更省心：具有辨識準確率高達99.5%的車牌辨識技術，並且使用邊緣運算可在150ms內辨識完畢，從而消除辨識過程的延遲，快速有效率！<br /><br />
                        2 ）更安心：CarDay 引入的 Nexuni 智慧停車系統提供了高效、靈活且智能的後端管理平台系統，包括電子發票開立、月票註冊、遠端裝置管理、實時停車狀態監控，還能根據特定需求客製化停車導引和電動車充電管理等。再者，系統結合AI 影像分析技術，能即時偵測如違停、設施故障等異常情況，進而降低人工監控的需求。停車好放心！<br /><br />
                        3 ）更貼心：支持多種支付方式快速繳費，讓你輕鬆繳費不麻煩！<br /><br />
                        8月16日起，CarDay 將於新竹湖口啟動四個新停車場；到月底，桃園八德區也將有三個停車場加入我們的網絡。屆時，CarDay 將在桃園、新竹及台中共營運16個停車場。若您為附近居民且有月票登記需求請洽：<a href="http://carday.nexuni.com">carday.nexuni.com</a>。若您對優質停車場也有合作意向，歡迎聯繫我們：<a href="mailto:contact@nexuni.com">contact@nexuni.com</a><br>
                        <a href="https://www.instagram.com/nexuni.co/p/Cv7jhWkpGeP/">查看原文</a>`
        },
        {
          "id": "latest_news_4",
          "category": "公司消息",
          "title": "Nexuni 參展台北國際安全科技應用博覽會",
          "author": "Nexuni小編",
          "date": "04.27.2023",
          "image": "/image/blog13.png",  // Assuming the image name
          "backgroundColor": "#a1a1a1",
          "hashtags": ["#實現智能城市新未來", "#搭載NXAI協助企業完成智能自動化"],
          "shortContent": "Nexuni 參展台北國際安全科技應用博覽會",
          "fullContent": `不論智慧停車場、智能物業管理，還是餐飲業自動化、半導體工廠自動化，Nexuni提供的每個解決方案，都是以勞動力的數位轉型需求為核心，從城市等應用場域出發，協助企業合作夥伴用更便捷和經濟的方式，超前佈局未來智慧城市管理和生活的新型態。<br /><br />
                        台北國際安全科技應用博覽會Secutech為台灣年度科技盛會，超過 250家企業將展出在5G物聯網架構下，整合AI、感測、雲端大數據等技術的各種應用。Nexuni 以 “What’s next？智能城市新未來！”為參展主軸，隆重推出具NXAI框架的五大全新產品線，歡迎好奇的大家到展覽現場一探究竟！<br /><br />
                        明天 (4/28) 是最後一天，錯過要再等一年～<br /><br />
                        台北國際安全科技應用博覽會<br />
                        展覽時間：2023/4/26 – 4/28<br />
                        展覽地點：台北南港展覽館一館4樓<br />
                        Nexuni攤位號碼：920<br /><br />
                        <a href="http://www.secutech.com.tw">台北國際安全科技應用博覽會網站</a><br>
                        <a href="https://www.instagram.com/nexuni.co/p/CriIlTurRRZ/">查看原文</a>`
        },
        {
          id: 'tech_art1',
          category: '科技小文',
          title: '什麼是Cyberduck?',
          author: '晴晴',
          date: '12 APR 2021',
          image: '/image/blog1.png',
          backgroundColor: '#52b4c1',
          hashtags: ['#網路小常識', '#Cyberduck'],
          shortContent: '什麼是Cyberduck?',
          fullContent: `Cyberduck 是個開放原始碼的免費 FTP(檔案傳輸協定) 傳檔工具，分別提供
                        Windows 與 Mac 電腦等版本。
                        與其他 FTP 傳檔軟體最大的不同是，Cyberduck 的使用方式非常輕易、簡單，除去一堆連線訊息、登入資訊…等密密麻麻的文字，只有一個視窗顯示遠端伺服器的檔案與資料夾。
                        上傳檔案時從本地電腦的桌面拉檔案進去，下載檔案時，再從遠端的Cyberduck 視窗將檔案或資料夾拉回來本地端的桌面或其他資料夾即可。
                        除此之外，Cyberduck 還支援了各種鍵盤快速鍵的操作，不論是下載、上傳、切換目錄、線上編輯….等操作，都可透過各種按鍵組合快速執行哦！`
        },
        {
          id: 'tech_art2',
          category: '科技小文',
          title: '裝軟體的貨櫃？',
          author: 'BO',
          date: '26 MAR 2021',
          image: '/image/blog2.png',
          backgroundColor: '#4cb3b6',
          hashtags: ['#貨櫃', '#集裝箱', '#docker', '#gadgethi'],
          shortContent: '裝軟體的貨櫃？',
          fullContent: `這幾日長榮的一個大型貨櫃輪長賜輪（EVER GIVEN）卡在埃及蘇伊士運河，引發了全球的的關注。大家知道嗎
                        ，在耕致科技，我們也有大型貨櫃哦！！只是是虛擬的貨櫃(Container)，哈哈！<br />
                        在十九世紀以前，貨輪裝的可不是貨櫃，而是散裝的貨品。每個港口都有一群人負責將貨品裝箱上船。二戰之後出現了集裝箱化(containerization)革命，貨船有了標準化的貨櫃規格，封閉的空間不僅讓送件人不必擔心貨品運送途遭到不當的處置，也最大化的利用了船上的空間。<br />
                        有趣的是在軟體的世界裏，也經歷了集裝箱化(containerization)革命！過去，將軟體安裝到不同的裝置上是個痛苦的過程。因爲不同的系統帶來大量的相容性問題。在耕致科技，工程師們用Docker,一種將軟體包裝成貨櫃的軟體，將程式碼從系統裏面乾淨的分離，裝箱。這不僅僅避免了轉移到不同裝置上遇到的相容性問題，也讓更新軟體又快又方便，傳送更新檔就像在運送貨櫃一樣!<br />
                        大家可以去看Docker的logo上面隻藍色鯨魚上，背著的就是一個一個的貨櫃呦！這樣的比喻是否相當傳神呢？`
        },
        {
          id: 'tech_art6',
          category: '科技小文',
          title: '競爭條件（race condition)',
          author: '哥老',
          date: '19 JAN 2021',
          image: '/image/blog6.png',
          backgroundColor: '#5ab6a1',
          hashtags: ['#寫程式小技巧', '#BUG'],
          shortContent: '競爭條件（race condition)',
          fullContent: `gadgetHi科技團隊至今已開發了相當多的系統（可由我們的專案介紹中了解更多），我們的軟體工程師在這過程中已經歷過許多大大小小的軟體錯誤。這些蟲子(bug)當中
                        ，大部分都能夠在開發初期就被發現並且解決，但是有些系統上的錯誤卻是很難在第一時間被發現的。其中一種此類型的系統錯誤便是競爭條件(race condition)，這種錯誤常發生在兩個執行緒同時讀取且修改同一個儲存體的內容。因為這個儲存體一次只能執行一個指令，所以不同執行緒間讀取跟寫入的順序將會變得非常重要。競爭條件這樣的特性也造成有時候系統很穩定，但就在某個瞬間整個系統就發生錯誤並中斷跳出。<br />
                        假設「豆日子」店內點餐系統中潛在著競爭條件危機的情況下店內有：<br />
                        1.三台點餐機<br />
                        2.一個資料庫用來儲存今天所有的訂單資訊<br />
                        我們必須要將當天店內所有的訂單都給一個連續的訂單編號，第一筆單001, 第二筆單002以此類推。所以每一台點餐機送出的訂單要先檢查資料庫中上一筆訂單的編號然後加1以得到這筆訂單的編號。<br />
                        這時候競爭條件就有可能出現了：<br />
                        如果同時有兩台點餐機送出A,B訂單，就有可能出現系統先幫A訂單查詢資料庫上一筆訂單編號，然後在A訂單的新編號還沒寫進資料庫之前，B訂單又進來查詢資料庫上一筆訂單編號了。這樣的結果就會使A跟B的單號變成一樣，造成系統的其他部分出現錯誤，以及店員會誤認為這其實是一筆訂單。<br />
                        至於這樣的問題要如何防範呢？關注gadgetHi 科技小文，未來的某一篇可能會做出解答（？！），或是來gadgetHi台北總部（木柵路二段149號）一起吃個豆花聊聊吧！`
        },
        {
          id: 'tech_art8',
          category: '科技小文',
          title: '「172.217.27.132」這組密碼代表什麼意思呢？',
          author: '蟲子',
          date: '06 DEC 2020',
          image: '/image/blog8.png',
          backgroundColor: '#5ab6a1',
          hashtags: ['#ip與網址', '#網路小常識'],
          shortContent: '「172.217.27.132」這組密碼代表什麼意思呢？',
          fullContent: `你有試過嗎？如果在自己的瀏覽器上方打上172.217.27.132會連到google的頁面
                        ，也就是網址「https://www.google.com 」的位置，其實他的ip 是「172.217.27.132 」但是因為ip的數字記憶上較困難，因此才有網址的出現我們一般使用的都是網址，像https://www.google.com 這種形式，稱為這個家主人的名字，ip則是地址的位置，例如：台北市xxxxx4號3樓，而電腦只認識送貨的地址，因此我們打https://www.google.com 時，電腦會透過另ㄧ個叫DNS( 像電話簿一樣)的服務查找你要的ip位置。看完以上的小知識之後，下次不妨試著用ip前往網站吧！`
        }
      ],
      categories: [
        {
          title: '公司消息',
          links: [
            { href: '#latest_news_1', text: 'Nexuni X Acer 智慧城市展'},
            { href: '#latest_news_2', text: 'AI智慧停車場示範場動土開工'},
            { href: '#latest_news_3', text: '全新概念的未來世代停車場營運商品牌 - CarDay 來了！'},
            { href: '#latest_news_4', text: 'Nexuni 參展台北國際安全科技應用博覽會'},
          ]
        },
        {
          title: '科技小文',
          links: [
            { href: '#tech_art1', text: '什麼是Cyberduck?' },
            { href: '#tech_art2', text: '裝軟體的貨櫃？' },
            { href: '#tech_art6', text: '競爭條件（race condition)' },
            { href: '#tech_art8', text: '「172.217.27.132」這組密碼代表什麼意思呢？' },
          ]
        },
        // Add more categories here...
      ]
    };
    this.readMore = this.readMore.bind(this);
  }

  readMore(e) {
    var dots = e.target.parentElement.children[3];
    var moreText = e.target.parentElement.children[4];
    var btnText = e.target;

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "閱讀更多"; 
      moreText.style.display = "none";
    } 
    else {
      dots.style.display = "none";
      btnText.innerHTML = "收回"; 
      moreText.style.display = "inline";
    }
  }

  update_lang(current_lang) {
    this.setState({
      lang: current_lang
    }, () => {
      console.log(this.state.lang)
    })
  }

  general_switch_page(path, data) {
    if (path !== "/BlogPage" || path !== "/") {
      setCookie("lang", this.state.lang)
      window.location.href = path + "?page=" + data
    }
    else {
      // pass
    }
  }

  render() {
    return (
      <div id="body">
        <Navbar lang={this.state.lang} updateLangFunc={this.update_lang} />
        <div className="main_section main_blog_section">
          <div className="blog_section">
            {this.state.content.map((item) => (
              <div className="blog_item" id={item.id} key={item.id}>
                <div className="blog_item_detail">
                  <h6 className="pic_sub_title">{item.category}</h6>
                  <h3 className="pic_title">{item.title}</h3>
                  <h6 className="pic_sub_title">{item.author} - {item.date}</h6>
                </div>
                <div className="img_holder" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}${item.image})`, backgroundColor: item.backgroundColor }}></div>
                <div className="blog_content">
                  <div className="hashtag">
                    {item.hashtags.map((hashtag, index) => (
                      <React.Fragment key={index}>
                        <a href="">{hashtag}</a> {' '}
                      </React.Fragment>
                    ))}
                    <br />
                  </div>
                  {item.shortContent}
                  <br />
                  ----<br />
                  <span id="dots">...</span>
                  <span id="more" style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: item.fullContent }}></span>
                  <br /><br />
                  <a onClick={this.readMore} id="readMoreBtn">閱讀更多</a>
                </div>
                <div className="lineBreaker" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/image/line_breaker.png)` }}></div>
              </div>
            ))}
          </div>

          <div className="search_section">
            {this.state.categories.map((category, index) => (
              <div className="category_section" key={index}>
                <h3 className="category_title">{category.title}</h3>
                <hr />
                <div className="category_btn">
                  {category.links.map((link, linkIndex) => (
                    <React.Fragment key={linkIndex}>
                      <a href={link.href}>{link.text}</a><br /><hr />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <Footer lang={this.state.lang} general_switch_page={this.general_switch_page} />
      </div>
    );
  }
}

export default BlogPage;
