import React, { Component } from 'react';
import '../css/LogoWall.css';

class LogoWall extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {

        return (
          <div className="marquee">
		    <div className="marquee__group">
		    	{/*<img src="/assets/logowall_urobot.svg" />*/}
		      <img src="/assets/logowall_nvidia.svg" />
		      <img src="/assets/logowall_ur.webp" />
		      <img src="/assets/logowall_cbm.svg" />
		      <img src="/assets/logowall_ingensys.svg" />
		      <img src="/assets/logowall_mapletree.svg" />
		      <img src="/assets/logowall_acer.svg" />
		      <img src="/assets/logowall_telecom.svg" />
		      <img src="/assets/logowall_linepay.svg" />
		      <img src="/assets/logowall_ubereats.svg" />
		      <img src="/assets/logowall_easycard.svg" />
		      <img src="/assets/logowall_doday.svg" />
		      <img src="/assets/logowall_carday.svg" />
		      <img src="/assets/logowall_credit.svg" />
		      <img src="/assets/logowall_hsinchu.svg" />
		      <img src="/assets/logowall_taichung.svg" />
		      <img src="/assets/logowall_taoyuan.svg" />
		      <img src="/assets/logowall_miaoli.svg" />
		    </div>

		    <div className="marquee__group">
		    	{/*<img src="/assets/logowall_urobot.svg" />*/}
		      <img src="/assets/logowall_nvidia.svg" />
		      <img src="/assets/logowall_ur.webp" />
		      <img src="/assets/logowall_cbm.svg" />
		      <img src="/assets/logowall_ingensys.svg" />
		      <img src="/assets/logowall_mapletree.svg" />
		      <img src="/assets/logowall_acer.svg" />
		      <img src="/assets/logowall_telecom.svg" />
		      <img src="/assets/logowall_linepay.svg" />
		      <img src="/assets/logowall_ubereats.svg" />
		      <img src="/assets/logowall_easycard.svg" />
		      <img src="/assets/logowall_doday.svg" />
		      <img src="/assets/logowall_carday.svg" />
		      <img src="/assets/logowall_credit.svg" />
		      <img src="/assets/logowall_hsinchu.svg" />
		      <img src="/assets/logowall_taichung.svg" />
		      <img src="/assets/logowall_taoyuan.svg" />
		      <img src="/assets/logowall_miaoli.svg" />
		    </div>
		  </div>
        ) 
  }

}

export default LogoWall;