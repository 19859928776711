import React from 'react';
import PropTypes from 'prop-types';

export default class ReadMoreReact extends React.Component {
	constructor(props){
		super(props);

    // let args = [
    //   this.props.text,
    //   this.props.min,
    //   this.props.ideal,
    //   this.props.max
    // ];

    this.state = {
    	text: this.props.text,
      min: this.props.min,
      ideal: this.props.ideal,
      max: this.props.max
    }

    // const [primaryText, secondaryText] = trimText(...args);
		// this.state = { displaySecondary: false, primaryText , secondaryText, readMoreText: this.props.readMoreText };
	}

	setStatus() {
		let display = !this.state.displaySecondary;
		this.setState({displaySecondary: display});
	}

	componentDidMount() {
    let args = [
      this.state.text,
      this.state.min,
      this.state.ideal,
      this.state.max
    ];

    const [primaryText, secondaryText] = trimText(...args);
		this.setState({ displaySecondary: false, primaryText , secondaryText, readMoreText: this.props.readMoreText });
  }

	componentWillReceiveProps(nextProps) {
    if (nextProps.text !== this.state.text) {
      this.setState({ text: nextProps.text }, () => {
      	let args = [
		      this.state.text,
		      this.state.min,
		      this.state.ideal,
		      this.state.max
		    ];

		    const [primaryText, secondaryText] = trimText(...args);
				this.setState({ displaySecondary: false, primaryText , secondaryText, readMoreText: this.props.readMoreText });
      });
    }
    if (nextProps.readMoreText !== this.state.readMoreText) {
      this.setState({ readMoreText: nextProps.readMoreText });
    }
  }

	render() {
		let displayText;
		if (!this.state.secondaryText) {
			displayText = (
			<div className="display-text-group">
				<span className="displayed-text">
					{`${this.state.primaryText} ${this.state.secondaryText}`}
				</span>
			</div>);
		}
		else if (this.state.displaySecondary) {
			displayText = (
			<div className="display-text-group">
				<span className="displayed-text"
					onClick={this.setStatus.bind(this)}>
					{`${this.state.primaryText} ${this.state.secondaryText}`}
				</span>
			</div>);
		} else {
			displayText = (<div className="display-text-group">
				<span className="displayed-text">
          {this.state.primaryText}<span style={{ display: "none"}}>{this.state.secondaryText}</span>
				<div className="read-more-button"
						 onClick={this.setStatus.bind(this)}>{this.state.readMoreText}</div>
				</span>
			</div>);
		}

		return displayText;
	}
}

ReadMoreReact.propTypes = {
  text: PropTypes.string.isRequired,
  min: PropTypes.number,
  ideal: PropTypes.number,
  max: PropTypes.number,
	readMoreText: PropTypes.string,
};

ReadMoreReact.defaultProps = {
  readMoreText: "read more",
};





const PUNCTUATION_LIST = [".",",","!","?","'","{","}","(",")","[","]", "/"];

const trimText = (text, min = 80, ideal = 100, max = 200) => {
	//This main function uses two pointers to move out from the ideal, to find the first instance of a punctuation mark followed by a space. If one cannot be found, it will go with the first space closest to the ideal.

	if (max < min || ideal > max || ideal < min) { 
		throw new Error("The minimum length must be less than the maximum, and the ideal must be between the minimum and maximum.")
	}

	if (text.length < ideal) {
		return [text, ''];
	}

	let pointerOne = ideal;
	let pointerTwo = ideal;
	let firstSpace, resultIdx;

	const setSpace = (idx) => {
		if (spaceMatch(text[idx])) { firstSpace = firstSpace || idx }
	}

	while (pointerOne < max || pointerTwo > min) {
		if (checkMatch(pointerOne, text, max, min)) {
			resultIdx = pointerOne + 1
			break;
		} else if (checkMatch(pointerTwo, text, max, min)) {
			resultIdx = pointerTwo + 1;
			break;
		} else {
			setSpace(pointerOne);
			setSpace(pointerTwo);
		}

		pointerOne++;
		pointerTwo--;
	}

	if (resultIdx === undefined) {
		if (firstSpace && firstSpace >= min && firstSpace <= max) { 
			resultIdx = firstSpace;
		} else if (ideal - min < max - ideal) {
			resultIdx = min;
		} else {
			resultIdx = max;
		}
	}

	return [text.slice(0, resultIdx), text.slice(resultIdx).trim()];
}

const spaceMatch = (character) => {
	if (character === " ") { return true }
}

const punctuationMatch = (idx, text) => {
	let punctuationIdx = PUNCTUATION_LIST.indexOf(text[idx]);
	if (punctuationIdx >= 0 && spaceMatch(text[idx + 1])) 
	{
		return true;
	}
}

const checkMatch = (idx, text, max, min) => {
	if (idx < max && idx > min && punctuationMatch(idx, text)) {
		return true;
	}
}