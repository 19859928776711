import React, { Component } from 'react';

import { setCookie, getCookie } from '../utils/cookie_utils.js'

import WordSec from '../components/WordSec'
import ListSec from '../components/ListSec'
import SlideShow from '../components/SlideShow'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import '../css/ContactUsPage.css';


class ContactUsPage extends Component {

  constructor(props) {
    var lang = "zh"
    if (getCookie("lang") !== "") {
      lang = getCookie("lang")
    }

    super(props);

    this.state = {
      lang: lang,
      title: {"en": "Our Info", "zh": "聯絡資訊", "ja": "お問い合わせ"},
      location: {
        "en": {
          "title": "Locations",
          "content": [{"title": "Hsinchu HQ", "addr": "6F-2, No. 31, Huanke 1st Rd., Zhubei City, Hsinchu County 302, Taiwan (R.O.C.)"}, {"title": "NEXUNI SINGAPORE PTE. LTD.", "addr": "1, COLEMAN STREET, THE ADELPHI, #10-06, Singapore 179803"}]
        }, 
        "zh": {
          "title": "地點",
          "content": [{"title": "新竹總部", "addr": "302新竹縣竹北市環科一路31號6樓之二"}, {"title": "NEXUNI SINGAPORE PTE. LTD.", "addr": "1, COLEMAN STREET, THE ADELPHI, #10-06, Singapore 179803"}]
        }, 
        "ja": {
          "title": "所在地",
          "content": [{"title": "新竹本社", "addr": "台湾新竹県竹北市環科一路31号6F-2"}, {"title": "NEXUNI SINGAPORE PTE. LTD.", "addr": "1, COLEMAN STREET, THE ADELPHI, #10-06, Singapore 179803"}]
        }
      },
      email: {
        "en": {
          "title": "Email",
          "content": ["Contact us with Email: <a href='mailto:contact@nexuni.com'>contact@nexuni.com</a>", "Questions about parking lot, please contact us with Email: <a href='mailto:parking@nexuni.com'>parking@nexuni.com</a>"]
        },
        "zh": {
          "title": "信箱",
          "content": ["聯絡我們信箱: <a href='mailto:contact@nexuni.com'>contact@nexuni.com</a>", "停車場相關問題請聯絡: <a href='mailto:parking@nexuni.com'>parking@nexuni.com</a>"]
        },
        "ja": {
          "title": "メール",
          "content": ["メールでお問い合わせ: <a href='mailto:contact@nexuni.com'>contact@nexuni.com</a>", "駐車場についてのお問い合わせ: <a href='mailto:parking@nexuni.com'>parking@nexuni.com</a>"]
        }
      },
      tele: {
        "en": {
          "title": "Customer Service",
          "content": ["Customer Service: +88635551566 (Hsinchu)"]
        },
        "zh": {
          "title": "聯絡電話",
          "content": ["聯絡電話: +88635551566 (新竹)"]
        },
        "ja": {
          "title": "カスタマーサービス",
          "content": ["カスタマーサービス: +88635551566 (新竹)"]
        }
      }


    }

    this.update_lang = this.update_lang.bind(this)
    this.general_switch_page = this.general_switch_page.bind(this)
  }

  update_lang(current_lang) {
    this.setState({
      lang: current_lang
    }, () => {
      console.log(this.state.lang)
    })
  }

  general_switch_page(path, data) {
    if (path !== "/ContactUsPage" || path !== "/") {
      setCookie("lang", this.state.lang)
      window.location.href = path + "?page=" + data
    }
    else {
      // pass
    }
  }


  render() {

        return (
            <div id="body">
              <Navbar lang={this.state.lang} updateLangFunc={this.update_lang} />
      
              <div className="main_section">
                
                  <div className="v2_contact_us_holder">
                    <h1 className="v2_contact_us_title">{this.state.title[this.state.lang]}</h1>
                    <div className="v2_contact_us_img_holder">
                      <div className="v2_contact_us_img" style={{backgroundImage: 'url('+"image/office2.jpg"+')'}}>{this.state.location[this.state.lang].title}</div>
                      <div className="v2_contact_us_info_block location" style={{backgroundImage: 'url('+"image/cu_bg.jpg"+')'}}>
                        {this.state.location[this.state.lang].content.map(item => 
                          <>
                            <h3>{item["title"]}</h3>
                            <h4>{item["addr"]}</h4>
                            <hr />
                          </>
                        )}
                      </div>
                      <div className="v2_contact_us_img dark" style={{backgroundImage: 'url('+"image/office1.jpg"+')'}}></div>
                      <div className="v2_contact_us_img dark" style={{backgroundImage: 'url('+"image/office4.jpg"+')'}}></div>
                      <div className="v2_contact_us_img" style={{backgroundImage: 'url('+"image/office3.jpg"+')'}}>{this.state.tele[this.state.lang].title}<br />{this.state.email[this.state.lang].title}</div>
                      <div className="v2_contact_us_info_block email" style={{backgroundImage: 'url('+"image/cu_bg.jpg"+')'}}>
                      {this.state.tele[this.state.lang].content.map((item) => 
                        <h3 dangerouslySetInnerHTML={{__html: item}}></h3>
                      )}
                      <hr />
                      {this.state.email[this.state.lang].content.map((item) => 
                        <h3 dangerouslySetInnerHTML={{__html: item}}></h3>
                      )}
                      
                      
                      </div>
                    </div>
                  </div>

              </div>

              <Footer lang={this.state.lang} general_switch_page={this.general_switch_page}/>
      
            </div>
      
        );}

}
export default ContactUsPage;
