import React, { Component } from 'react'

import '../css/OpenSlideShow.css';

class SlideShow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slideIndex: 0,
            fade_out_i: "", 
            img: this.props.img,
            fade_out_list: new Array(this.props.img_num).fill(""),
            style: this.props.style,
            version: this.props.version ? this.props.version : "",
            btn_name: {"en": "Learn more", "zh": "了解更多", "ja": "詳しくはこちら"},
            flag: true
        }

        this.slides = React.createRef();

        this.showSlides = this.showSlides.bind(this)
    }

    componentDidMount() {
        setTimeout(this.showSlides, 3000);
    }

    showSlides() {
        var i;
        if (this.slides.current && this.state.flag) {
            this.state.flag = false
            var slides = this.slides.current.children;

            if (slides.length > 0) {

                i = this.state.slideIndex

                this.state.slideIndex++;

                if (this.state.slideIndex > slides.length) {this.state.slideIndex = 1}


                slides[this.state.slideIndex-1].style.display = "flex";
                
                
                if (i-1 >= 0) { 
                    for (var k = 0; k < this.state.fade_out_list.length; k++) {
                        this.state.fade_out_list[k] = ""
                    }
                    this.state.fade_out_list[i-1] = "fade_out"
                }
                

                this.setState({
                    slideIndex: this.state.slideIndex,
                    fade_out_i: this.state.fade_out_list,
                }, () => {
                    if ((this.props.img_num).toString() !== "1") {

                        setTimeout(() => {
                            if (i-1 >= 0) {
                                slides[i-1].style.display = "none";
                            }
                        }, 1000);
                        setTimeout(() => {this.state.flag = true;this.showSlides()}, 10000);
                    }
                })

            }

            
        }
        

    }

    emptyFunc() {}
    
    
    render() {
        var title_s = {}
        var content_s = {}
        if (this.state.style) {
            title_s = this.state.style["title"]
            content_s = this.state.style["content"]
        }

        return (
            <div ref={this.slides} onClick={this.props.onClick ? this.props.onClick : this.emptyFunc} className={this.state.version+"slideshow-container"}>
                {this.state.img.map((item, index) => <div key={index} className={"mySlides fade_in " + this.state.fade_out_list[index]} style={{backgroundImage: `url(${item})`}}>
                    {(this.props.img_num).toString() !== "1" ? <div className="numbertext">{(index+1) + " / " + this.props.img_num}</div>: <></>}
                    {this.props.arrow_flag ? <img className="arrow_icon fade" src={"/assets/mainpage_nexuni_white_icon.png"}/> : <></>}

                    <div className={this.state.version+"title_holder"}>
                        {this.props.logo_flag ? <img src={"/assets/mainpage_nexuni_white.png"}/> : <></>}
                        {this.props.title[index] === "" || this.props.title[index] === undefined ? <></> : <h1 style={title_s}>{this.props.title[index]}</h1>}
                        {this.props.content[index] === "" || this.props.content[index] === undefined ? <></> : <h4 style={content_s}>{this.props.content[index]}</h4>}
                        {this.props.action[index] === "" || this.props.action[index] === undefined ? <></> : <button onClick={this.props.action[index]}>{this.state.btn_name[this.props.lang]}</button>}
                    </div>

                    </div>)}
                
            </div>
        );
    }
}

export default SlideShow;