import React, { Component } from 'react'

class PartnerSec extends Component {

    emptyFunc() {}
    
    render() {

        return (
            <div className={this.props.img.length < 4 ? "partner_section partner_center" : "partner_section"}>
                {this.props.img.map((img_src, index) => 
                <div key={index} className="partner_item" onClick={this.props.action[index] !== "" ? this.props.action[index] : this.emptyFunc}>
                    <div className="img_holder">
                        <img src={process.env.PUBLIC_URL + "/image/" + img_src} />
                    </div>  
                    <h3>{this.props.title[index]}</h3>
                </div>)}
                
            </div>
        );
    }
}

export default PartnerSec;