import React, { Component } from 'react'

class ListSec extends Component {

    
    
    render() {
        {console.log(this.props.action === undefined, this.props.action === "")}
        return (
            <div className="list_section">
                <h2 onClick={this.props.action === undefined || this.props.action === "" ? () => {} : this.props.action } className={this.props.action === undefined || this.props.action === "" ? "list_title" : "list_title list_btn_style" }>{this.props.main_title}</h2>
                <div className="list_holder">
                {this.props.content.map((item, index) => {
                    return (
                        <div key={index} className="list_item">
                            <h3>{this.props.title[index]}</h3>
                            <hr/>
                            {item.map((inner_item, inner_index) => <h5 key={inner_index} dangerouslySetInnerHTML={{__html: inner_item}}></h5>)}
                        </div>
                    )
                })}
                </div>
            </div>
        );
    }
}

export default ListSec;