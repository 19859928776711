import React, { Component } from 'react'

class TopPicDownWordSec extends Component {
    
    
    render() {
    	var t_style = this.props.style["text"];
    	var i_style = this.props.style["img"];
        return (
        	<div className="top_pic_down_word_section">
                <div className="img_holder">
				    <img src={`${process.env.PUBLIC_URL}/image/`+this.props.img} style={i_style}/>
                </div>
				<div className="down_word_section">
					{this.props.title !== "" ? <h3 style={t_style}>{this.props.title}</h3> : <></>}
					{this.props.content !== "" ? <h4 style={t_style}>{this.props.content}</h4> : <></>}
				</div>
			</div>
        );
    }
}

export default TopPicDownWordSec;