import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Fade from 'react-reveal/Fade';
import Flash from 'react-reveal/Flash';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

import { setCookie, getCookie } from '../utils/cookie_utils.js'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import LogoWall from '../components/LogoWall'

import WordSec from '../components/WordSec'
import SlideShow from '../components/SlideShow'

import '../css/old_web.css';

class StoryPage extends Component {
  constructor(props) {
    var lang = "zh"
    if (getCookie("lang") !== "") {
      lang = getCookie("lang")
    }

    super(props);
    this.state = {
      lang: lang,
      slide_show_content: {
        action: [""],
        title: {"en": ["Customer Stories"], "zh": ["實際案例"], "ja": ["顧客事例"]},
        content: {"en": [""], "zh": [""], "ja": [""]},
        img: ["image/story_page_intro.jpg"]
      },
      word_section_content: {
        style: {"div": {'padding-top': '50px'}, "h3": {}, "h4": {}},
        content: {
          "en": ["Nexuni's AI automation solutions have considerable number of deployments in areas such as smart parking, facility management, and smart factories. In Singapore, we have also successfully deployed smart security and smart toilet management systems, achieving automated operational management. Below are a few selected customer case studies."],
          "zh": ["Nexuni的AI自動化解決方案在智慧停車、設施管理以及智慧工廠等領域已有相當多部署案例。在新加坡，我們成功也部署了智慧保全及智慧廁所管理系統，達成自動化營運管理。以下為幾個精選的客戶案例。"],
          "ja": ["NexuniのAI自動化ソリューションは、スマートパーキング、施設管理、スマートファクトリーなどの分野で多くの導入事例があります。シンガポールでは、スマートセキュリティおよびスマートトイレ管理システムも成功裏に導入し、自動化された運営管理を実現しました。以下に選りすぐりの顧客事例を紹介します。"]
        },
        title: {"en": [""], "zh": [""], "ja": [""]}
      },
      second_word_section_content: {
        style: {"div": {'padding-top': '20px'}, "h3": {'text-align': 'center'}, "h4": {}},
        title: {"en": ["Customer & Partners"], "zh": ["客戶與合作夥伴"], "ja": ["顧客とパートナー"]},
        content: {
          "en": ["Below are our valued partners and customers who have collaborated with Nexuni. We highly value these partnerships as they have propelled our business forward, not only enhancing operational efficiency but also achieving more intelligent and automated management. Through our joint efforts, we look forward to continuing to drive innovation and progress in the industry."],
          "zh": ["以下是與Nexuni攜手合作的尊貴夥伴和客戶。我們非常珍視這些合作關係，因為正是這些合作推動了我們的業務向前發展，不僅提升了運營效率，也實現了更加智慧化和自動化的管理。透過我們的共同努力，我們期待繼續推動業界的創新與進步。"],
          "ja": ["以下はNexuniと協力している尊敬されるパートナーおよび顧客です。これらの協力関係を非常に重視しています。なぜなら、それらが私たちのビジネスを前進させ、運営の効率を向上させるだけでなく、より賢く自動化された管理を実現しているからです。共同の努力を通じて、産業の革新と進歩を引き続き推進していくことを期待しています。"]
        }
      },


      
    }

    this.general_switch_page = this.general_switch_page.bind(this)
    this.update_lang = this.update_lang.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lang !== this.state.lang) {
      this.setState({ lang: nextProps.lang });
    }
  }

  update_lang(current_lang) {
    this.setState({
      lang: current_lang
    }, () => {
      console.log(this.state.lang)
    })
  }

  general_switch_page(path, data) {
    if (path !== "/StoryPage" || path !== "/") {
      setCookie("lang", this.state.lang)
      window.location.href = path + "?page=" + data
    }
    else {
      // pass
    }
  }


  render() {

        return (
          <div id="body">
          <Navbar lang={this.state.lang} updateLangFunc={this.update_lang} />
          <div className="main_section" >
            <SlideShow lang={this.state.lang} title={this.state.slide_show_content.title[this.state.lang]} content={this.state.slide_show_content.content[this.state.lang]} action={this.state.slide_show_content.action} img={this.state.slide_show_content.img} img_num={"1"} arrow_flag={false} logo_flag={true}/>
            <WordSec className="general_section" 
                      title={this.state.word_section_content.title[this.state.lang]} 
                      content={this.state.word_section_content.content[this.state.lang]} 
                      style={this.state.word_section_content.style}/>
            <IGWall lang={this.state.lang} general_switch_page={this.general_switch_page}/>
            <WordSec className="general_section" 
                      title={this.state.second_word_section_content.title[this.state.lang]} 
                      content={this.state.second_word_section_content.content[this.state.lang]} 
                      style={this.state.second_word_section_content.style}/>
            <LogoWall />
            <Footer lang={this.state.lang} general_switch_page={this.general_switch_page}/>
          </div>
          </div>
        ) 
  }

}


class IGWall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.lang,
      col: 3,
      itemData: [
    {
        "title": {"zh": "AI 智慧停車場：台灣30+場域", "en": "AI Smart Parking: Over 30 Sites in Taiwan", "ja": "AIスマート駐車場：台湾の30以上のサイト"},
        "subtitle": {"zh": "", "en": "", "ja": ""},
        "path": {"main": "/TechPage", "id": "S1"},
        "img": "/assets/story/S1.jpg"
    },
    {
        "title": {"zh": "AI 智慧保全：新加坡工廠", "en": "AI Smart Security: Singapore Factory", "ja": "AIスマートセキュリティ：シンガポール工場"},
        "subtitle": {"zh": "", "en": "", "ja": ""},
        "path": {"main": "/TechPage", "id": "S2"},
        "img": "/assets/story/S2.jpg"
    },
    {
        "title": {"zh": "智慧路邊停車收費系統：頭份市", "en": "Smart Roadside Parking Payment System: Toufen City", "ja": "スマート路上駐車料金システム：頭份市"},
        "subtitle": {"zh": "", "en": "", "ja": ""},
        "path": {"main": "/TechPage", "id": "S3"},
        "img": "/assets/story/S3.jpg"
    },
    {
        "title": {"zh": "智慧廁所管理：新加坡", "en": "Smart Toilet Management: Singapore", "ja": "スマートトイレ管理：シンガポール"},
        "subtitle": {"zh": "", "en": "", "ja": ""},
        "path": {"main": "/TechPage", "id": "S4"},
        "img": "/assets/story/S4.jpg"
    },
    {
        "title": {"zh": "智慧店面自動化：DoDay豆日子", "en": "Smart Store Automation: DoDay", "ja": "スマートストア自動化：DoDay豆日子"},
        "subtitle": {"zh": "", "en": "", "ja": ""},
        "path": {"main": "/TechPage", "id": "S5"},
        "img": "/assets/story/S5.jpg"
    },
    {
        "title": {"zh": "AI協作式機器人應用：工廠自動化", "en": "AI Collaborative Robot Application: Factory Automation", "ja": "AI協働ロボットアプリケーション：工場自動化"},
        "subtitle": {"zh": "", "en": "", "ja": ""},
        "path": {"main": "/TechPage", "id": "S6"},
        "img": "/assets/story/S6.jpg"
    }
]
    }

    this.setCol = this.setCol.bind(this)
  }

  componentDidMount() {
    this.setCol();
    window.addEventListener('resize', this.setCol);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lang !== this.state.lang) {
      this.setState({ lang: nextProps.lang });
    }
  }

  setCol() {
    console.log("this.holder.innerWidth: ", window.innerWidth)
    if (window.innerWidth > 780) {
      this.setState({ col: 3 });
    }
    else if (window.innerWidth > 350) {
      this.setState({ col: 2 });
    }
    else{
      this.setState({ col: 1 });
    }
  }

  render() {

        return (
          <ImageList cols={this.state.col}>
            {this.state.itemData.map((item) => (
              <ImageListItem key={item.img} onClick={() => {this.props.general_switch_page(item.path["main"], item.path["id"])}}>
                <img
                  className="ig_pic"
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  className="ig_bar"
                  title={item.title[this.state.lang]}
                  subtitle={item.subtitle[this.state.lang]}
                  actionIcon={
                    <IconButton
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      aria-label={`info about ${item.title}`}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) 
  }

}

export default StoryPage;