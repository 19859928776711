import React, { Component } from 'react'

class WordBtn extends Component {
    
    
    render() {
        var div_style = (this.props.style ? this.props.style["div"] : {});
        var h3_style = (this.props.style ? this.props.style["h3"] : {});
        var h4_style = (this.props.style ? this.props.style["h4"] : {});

        return (
        	
            <div className="word_btn">
                {this.props.title.map((title, i) => (
                    <div onClick={this.props.action === undefined || this.props.action === "" ? () => {} : this.props.action } key={i} style={div_style}>
                        {title !== "" ? <h3 style={h3_style}>{title}</h3>:<></>}
                        {this.props.content[i] !== "" ? <h4 style={h4_style}>{this.props.content[i]}</h4>:<></>}
                    </div>
                ))}
                
            </div>
        );
    }
}

export default WordBtn;