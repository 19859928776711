import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { setCookie, getCookie } from '../utils/cookie_utils.js'
import Fade from 'react-reveal/Fade';
import '../css/Navbar.css';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_lang: this.props.lang, // en, zh, ja
      mobile_flag: false,
      nav_content: [{
        "href_link": "/MainPage", 
        "en": "Home",
        "zh": "首頁",
        "ja": "ホーム",
      },
      {
        "href_link": "/SolutionPage",
        "en": "Solutions", 
        "zh": "解決方案", 
        "ja": "ソリューション"
      },
      {
        "href_link": "/StoryPage", 
        "en": "Success Stories", 
        "zh": "實際案例", 
        "ja": "顧客事例"
      },
      {
        "href_link": "/GeneralPage?page=about_us", 
        "en": "About Us",
        "zh": "關於我們",
        "ja": "会社について",
      },
      {
        "href_link": "/ContactUsPage", 
        "en": "Contact Us",
        "zh": "聯絡我們",
        "ja": "お問い合わせ",
      },
      ]
    }

    this.open_nav = this.open_nav.bind(this)
    this.update_lang = this.update_lang.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lang !== this.state.lang) {
      this.setState({ current_lang: nextProps.lang });
    }
  }

  general_switch_page(path, data) {
    if (path !== "/MainPage" || path !== "/") {
      setCookie("lang", this.state.current_lang)
      window.location.href = path + "?page=" + data
    }
    else {
      // pass
    }
  }

  update_lang(lang) {
    setCookie("lang", lang);
    this.props.updateLangFunc(lang);
  }

  open_nav() {
    this.setState({
      mobile_flag: !this.state.mobile_flag
    })
  }

  render() {
        return (
          <>
            <div className={"v2_nav_section "+this.state.current_lang}>
              <button onClick={() => this.general_switch_page('/MainPage', '')}><img className="v2_nav_logo" src="/assets/mainpage_nexuni_black.png"/></button>
              <div className="v2_nav_btn_holder">
                {this.state.nav_content.map((item) => 
                  <a className="v2_nav_btn" 
                  href={item["href_link"]}>{item[this.state.current_lang]}</a>
                )}
                <div className="v2_nav_lang">
                  <span>&nbsp;[&nbsp;&nbsp;</span>
                  {this.state.current_lang !== "en" ? <button onClick={() => this.update_lang("en")} className="v2_nav_lang_btn">EN</button> : <></>}
                  {this.state.current_lang === "ja" ? <span>&nbsp;|&nbsp;</span> : <></>}
                  {this.state.current_lang !== "zh" ? <button onClick={() => this.update_lang("zh")} className="v2_nav_lang_btn">繁中</button> : <span>&nbsp;|&nbsp;</span>}
                  {this.state.current_lang === "en" ? <span>&nbsp;|&nbsp;</span> : <></>}
                  {this.state.current_lang !== "ja" ? <button onClick={() => this.update_lang("ja")} className="v2_nav_lang_btn">日本語</button> : <></>}
                  <span>&nbsp;&nbsp;]&nbsp;</span>
                </div>

                <div className="v2_append_nav_btn" onClick={this.open_nav}>三</div>
              </div>
            </div>

            {this.state.mobile_flag ? 
            <Fade duration="1000" delay="200">
              <div className="v2_mobile_tab">
                {this.state.nav_content.map((item) => 
                  <a className="v2_mnav_btn" 
                  href={item["href_link"]}>{item[this.state.current_lang]}</a>
                )}
              </div></Fade> : <></> }
            
          </>
        ) 
  }

}


export default Navbar;
