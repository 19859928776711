import React, { Component } from 'react'

class NewsItem extends Component {
    openInNewTab(url) {
        var win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    }
    
    render() {
        ;
        return (
            <div className="news_section background_color_grey">
                {this.props.content.map((item, index) => <div key={index} className="news_item">
                    <div className="img_holder" style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/image/" + item["img"]})`}}></div>
                    <div className="news_item_detail">
                        <h6 className="news_pic_sub_title">{item["title"]}</h6>
                        <h3 className="news_pic_title">{item["desc"]}</h3>
                        <h6 className="news_pic_sub_title">{item["date"]}</h6>
                        <button className="more_info_btn" onClick={() => this.openInNewTab(item["url"] === "PDF" ? `${process.env.PUBLIC_URL}/assets/pdf/news1.pdf` : item["url"])}>進一步了解</button>
                    </div>
                </div>)}
                
            </div>
        );
    }
}

export default NewsItem;