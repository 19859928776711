import React, { Component } from 'react'
import ReadMoreReact from './ReadMoreReact'

class WordPicSec extends Component {
    
    
    render() {
    	var rmtxt = this.props.readMoreText ? this.props.readMoreText : ""
    	var t_style = this.props.style["text"];
    	var i_style = this.props.style["img"];
        return (
        	<div className="word_pic_section">
				<div className="half_word_section">
					{this.props.title !== "" ? <h3 style={t_style}>{this.props.title}</h3> : <></>}
					{this.props.content !== "" && rmtxt === "" ? <h4 style={t_style} dangerouslySetInnerHTML={{__html: this.props.content}}></h4> : <></>}
					{rmtxt !== "" ? <h4 style={t_style}><ReadMoreReact text={this.props.content} readMoreText={this.props.readMoreText}/></h4> : <></>}
				</div>
				<img src={`${process.env.PUBLIC_URL}/image/`+this.props.img} style={i_style} />
			</div>
        );
    }
}

export default WordPicSec;